import {getFoalAggregate} from "./foals";
import {getSidePotsByEventId} from "./sidepots";

export const createEntry = async (app, entry) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const entriesCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("entries");
    const newEntry = await entriesCollection.insertOne(entry);
    const sidePots = await getSidePotsByEventId(app, entry.event);
    const aggregate =[
      {
        $lookup: {
          from: "sires",
          localField: "sire",
          foreignField: "_id",
          as: "sires",
        },
      },
      { $unwind: { path: "$sires", preserveNullAndEmptyArrays: true } },]

    const foal = await getFoalAggregate(app, entry.foal, aggregate);
    const sire = foal[0].sires;

    await createSidePotEntriesJoins(app, sidePots, newEntry.insertedId, sire);
    return newEntry;
  } catch (e) {
    console.log("Error creating entry:", e);
  }
};


const createSidePotEntriesJoins = async (app, sidePots, entryId, foalSire) => {
  const sidePotEntryJoins = [];

  for (const sidePot of sidePots) {
    if (sidePot.class !== 'LIMITED' && sidePot.class !== 'JUNIOR') {
      sidePotEntryJoins.push(createSidePotEntryJoin(app, sidePot._id, entryId));
    }

    if (foalSire && foalSire.limited && sidePot.class === 'LIMITED') {
      sidePotEntryJoins.push(createSidePotEntryJoin(app, sidePot._id, entryId));
    }

    if (foalSire && foalSire.junior && sidePot.class === 'JUNIOR') {
      sidePotEntryJoins.push(createSidePotEntryJoin(app, sidePot._id, entryId));
    }
  }

  await Promise.all(sidePotEntryJoins);
};

const createSidePotEntryJoin = async (app, sidePotId, entryId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sidePotEntryJoinsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("sidePotEntryJoins");
    return await sidePotEntryJoinsCollection.insertOne({sidePotId, entryId});
  } catch (e) {
    console.log("Error creating side pot entry join:", e);
  }
};

export const checkEntryForFoal = async (app, eventId, foalId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const entriesCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("entries");
    const result = await entriesCollection.findOne({
      $and: [
        { event :  eventId },
        { foal:  foalId }
      ]
    })
    return result;
  } catch (e) {
    throw e;
  }
}
