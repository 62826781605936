export const getDamSire = async (app, damSireId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const damSiresCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("damSires");

    const damSire = await damSiresCollection.findOne({
      _id: { $oid: damSireId },
    });
    return damSire;
  } catch (error) {
    console.error("Error fetching DamSire:", error);
  }
};

export const getAllSortedDamSires = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const damSires = mongo.db(process.env.REACT_APP_DB_NAME).collection("damSires");

    const sortedDamSires = await damSires.find({}, { sort: { name: 1 } });
    return sortedDamSires;
  } catch (error) {
    console.error("Error fetching Sire:", error);
  }
};
