import React, {useState} from 'react';
import {Button, Checkbox, Col, Divider, Modal, Progress, Row, Tag, Upload} from "antd";
import {Form, Input, Select} from "formik-antd";
import axios from "axios";
import {UploadOutlined} from "@ant-design/icons";

const SireRenewalEditModal = ({
                                isModalOpen,
                                closeModal,
                                values,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                fileLists,
                                setFileLists
                              }) => {

  const countryList = ['USA', "Canada", "Brazil", "European Union", "Other"]
  const [fileUpload, setFileUpload] = useState({});
  const [progress, setProgress] = useState(0);
  const uploadImage = async (options) => {
    const {onError} = options;

    setFileUpload({});
    try {
      const {onSuccess, file, onProgress} = options;

      const fmData = new FormData();
      const config = {
        headers: {"Content-Type": file.type},

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({percent: (event.loaded / event.total) * 100});
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: {prefix: "papers", name: file.uid, filetype: file.type},
        },
      );

      await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: {"Content-Type": file.type},
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      onError({err});
    }
  };

  const handleOnChange = (field, {fileList}) => {
    setFileLists((prev) => ({...prev, [field]: fileList}));
    setFieldValue(field, fileList[0])
  };

  return (
    <Modal
      centered
      title={'Use this form to update Stallion information'}
      open={isModalOpen}
      onCancel={closeModal}
      onOk={closeModal}
      width={"80rem"}
      footer={null}
    >
      <Form layout="vertical">
        <Form.Item
          label="Contact Person"
          name="contact"
          hasFeedback={true}
          showValidateSuccess={true}
        >
          <Input name="contact" placeholder="Contact Person"/>
        </Form.Item>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={16}>
            <Form.Item
              label="Semen Availability"
              name="semenAvailability"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Semen Availability"
                name="semenAvailability"
                defaultValue={countryList}
              >
                {countryList.map(item => (
                  <Select.Option
                    key={item}
                    value={item}
                    id={item}
                  >
                    {item}
                  </Select.Option>
                ))}

              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Country"
              name="country"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Select
                placeholder="Country"
                name="country"
              >
                {countryList.map(item => (
                  <Select.Option
                    key={item}
                    value={item}
                    id={item}
                  >
                    {item}
                  </Select.Option>
                ))}

              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Stallion Station Name"
              name="stationName"
              hasFeedback={true}
            >
              <Input
                name="stationName"
                placeholder="Stallion Station Name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Stallion Station Address"
              name="stationAddress"
              hasFeedback={true}
            >
              <Input
                name="stationAddress"
                placeholder="Stallion Station Address"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Stallion Station Email"
              name="stationEmail"
              hasFeedback={true}
            >
              <Input
                name="stationEmail"
                placeholder="Stallion Station Email"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Stallion Station Phone"
              name="stationPhone"
              hasFeedback={true}
            >
              <Input
                name="stationPhone"
                placeholder="Stallion Station Phone"
              />
            </Form.Item></Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Website Link for Stallion Station"
              name="stationWebsite"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Input name="stationWebsite" placeholder="Website Link for Stallion Station"/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Website Link for Stallion Page"
              name="website"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Input name="website" placeholder="Website Link for Stallion Page"/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              label="Social Media Link"
              name="socialMedia"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Input name="socialMedia" placeholder="Social Media Link"/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Marketing Paragraph"
          name="marketingParagraph"
          hasFeedback={true}
          showValidateSuccess={true}
        >
          <Input.TextArea
            name="marketingParagraph"
            placeholder="Marketing Paragraph"
          />
        </Form.Item>
        <Divider/>
        <Row gutter={[32, 0]}>
          <Col xs={12} sm={6}>
            <Form.Item
              label="Junior"
              name="junior"
            >
              <Checkbox
                name='junior'
                checked={values.junior}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Item
              label="Limited"
              name="limited"
            >
              <Checkbox
                name='limited'
                checked={values.limited}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Item
              label="Barrels"
              name="barrels"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Checkbox name='barrels'/>
            </Form.Item>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Item
              label="Poles"
              name="poles"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <Checkbox name='poles'/>
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={12} sm={6}>
            {values?.junior &&
              <Form.Item
                name="firstCropBirthYear"
                label="Birth Year of First Foal Crop"
              >
                <Input
                  style={{width: "100px"}}
                  name="firstCropBirthYear" placeholder="Year"
                />
              </Form.Item>
            }
          </Col>
          <Col xs={12} sm={6}>
            {values?.limited &&
              <Form.Item label="Breeding Report" name="breedingReport">

                <Upload
                  accept="*"
                  name="breedingReport"
                  customRequest={(value) => uploadImage(value, 'breedingReport')}
                  onChange={(value) => handleOnChange('breedingReport', value)}
                  defaultFileList={fileLists.breedingReport}
                  className="image-upload-grid"
                >
                  {fileLists.breedingReport.length >= 1 ? null : (
                    <>
                      <Button icon={<UploadOutlined/>}>Upload</Button>
                      <Tag style={{marginBottom: "4px"}} color="red">
                        *Required for all limited Stallions.
                      </Tag>
                    </>
                  )}
                </Upload>
              </Form.Item>}
          </Col>
        </Row>

        <Divider orientation="center">Attachments</Divider>

        <Row gutter={[32, 0]}>
          <Col xs={12} sm={6}>
            <Form.Item label="Papers" name="papers">
              <Upload
                accept="*"
                name="papers"
                customRequest={(value) => uploadImage(value, 'papers')}
                onChange={(value) => handleOnChange('papers', value)}
                defaultFileList={fileLists.papers}
                className="image-upload-grid"
              >
                {fileLists.papers.length ? null : (
                  <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    gap: 3, justifyContent: 'center',
                    alignItems: 'flex-start'
                  }}>
                    <Button icon={<UploadOutlined/>}>Upload</Button>
                    <Tag color="red">
                      *Please upload papers.
                    </Tag>
                  </div>
                )}
              </Upload>

              {progress > 0 ? <Progress percent={progress}/> : null}
            </Form.Item>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Item label="Head Picture" name="headPicture">
              <Upload
                accept="*"
                name="pictures"
                customRequest={(value) => uploadImage(value, 'headPicture')}
                onChange={(value) => handleOnChange('headPicture', value)}
                defaultFileList={fileLists.headPicture}
                className="image-upload-grid"
              >
                {fileLists.headPicture.length ? null : (
                  <Button icon={<UploadOutlined/>}>Upload</Button>
                )}
              </Upload>

              {progress > 0 ? <Progress percent={progress}/> : null}
            </Form.Item>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Item label="Profile Picture" name="profilePicture">
              <Upload
                accept="*"
                name="profilePicture"
                customRequest={(value) => uploadImage(value, 'profilePicture')}
                onChange={(value) => handleOnChange('profilePicture', value)}
                defaultFileList={fileLists.profilePicture}
                className="image-upload-grid"
              >
                {fileLists.profilePicture.length ? null : (
                  <Button icon={<UploadOutlined/>}>Upload</Button>
                )}
              </Upload>

              {progress > 0 ? <Progress percent={progress}/> : null}
            </Form.Item>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Item label="Video" name="video">
              <Upload
                accept="*"
                name="video"
                customRequest={(value) => uploadImage(value, 'video')}
                onChange={(value) => handleOnChange('video', value)}
                defaultFileList={fileLists.video}
                className="image-upload-grid"
              >
                {fileLists.video.length ? null : (
                  <Button icon={<UploadOutlined/>}>Upload</Button>
                )}
              </Upload>
              {progress > 0 ? <Progress percent={progress}/> : null}
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        <div style={{
          display: "flex",
          justifyContent: 'flex-end',
          gap: "10px",
        }}>
          <Form.Item label="" name='cancel'>
            <Button
              size='large' onClick={() => {
              resetForm();
              closeModal();
            }}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item label="" name='submit'>
            <Button
              onClick={closeModal}
              size='large'>
              Save Changes
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default SireRenewalEditModal;