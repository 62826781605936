import { Typography } from "antd";
const { Title, Text, Paragraph } = Typography;

const PaymentDisclosure = () => {
  return (
    <>
      <Paragraph>
        To be eligible for TLM Payouts, enrollment fee must be paid in full, enrollment
        form and copy of foal papers must be on file with TLM office, and horse must
        be entered in the TLM event with TLM and entry fee paid in full.
      </Paragraph>
      <Paragraph>
        TLM Enrollments are due December 1st of the horse’s yearling year.
      </Paragraph>
      <Paragraph>
        A copy of the foal’s breed registration papers must be included with the
        enrollment.  If breed registration is not complete, foal may be enrolled as
        “pending” without registration number or papers.  Foal cannot compete for
        TLM payouts until the registration number and copy of papers are updated
        with TLM office.
      </Paragraph>
      <Paragraph>
        Receipt of this enrollment form does not guarantee enrollment.  If enrollment is
        not granted, fees will be refunded.  If enrollment is granted, receipt listing
        horse, dam’s sire, year of birth, date of enrollment, and nominator
        information will be emailed to the nominator email address on file.
      </Paragraph>
      <Paragraph>
        By checking this box and enrolling in Legendary Mamas LTD, I agree to allow
        this horse, any jockey competing with said horse, minor, professional, or
        amateur and/or my image(s) and/or likeness(s) to be used by Legendary
        Mamas for advertising purposes.
      </Paragraph>
    </>
  );
};

export default PaymentDisclosure;
