export const getSidePotsByEventId = async (app, eventId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sidePotCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("sidepots");
    return await sidePotCollection.aggregate([
      {$match: {eventId: eventId}}
    ]);
  } catch (e) {
    console.log("Error fetching side pots:", e);
    return [];
  }
};