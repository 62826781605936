import { Layout, Typography, Button } from "antd";
import React from "react";
import TLMenu from "../layout/TLMenu";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import { BannerImage } from "../components/BannerImage";
import { useLocation, useNavigate } from "react-router-dom";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const TLReceipt = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const props = location?.state?.foal;

  const addNewFoal = () => {
    // Navigate back to home with completely clear props
    navigate("/", { state: {} });
  };

  return (
    <Layout className="layout">
      <TLMenu />
      <BannerImage url="/race entry pic.png" />

      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <TLBreadCrumb />
        {props && (
          <div className="site-layout-content">
            <Title level={4}>
              Your foal enrollment has been submitted for {props.horseName}.
            </Title>
            <Paragraph>
              {" "}
              You will receive a receipt for your accepted payment. Our team
              will review your enrollment and get started on the approval
              process. You can print this page for your records.
            </Paragraph>
            <Paragraph>
              Please direct any questions to enrollments@tlmamas.com
            </Paragraph>

            <Title level={4}>Enrollment information: </Title>
            <Title level={5}>Total Amount paid: ${props.totalPrice}</Title>
            <Paragraph>Nominator: {props.nominatorName}</Paragraph>
            <Paragraph>Registration #: {props.registrationNumber}</Paragraph>
            <Paragraph>Horse Name: {props.horseName}</Paragraph>
            <Paragraph>Birth Year: {props.birthYear}</Paragraph>
            <Paragraph>Dam Sire: {props.damSire}</Paragraph>

            {/* <Button onClick={addNewFoal} type="link" block>
              Add another Foal
            </Button> */}
          </div>
        )}
      </Content>
      <TLFooter />
    </Layout>
  );
};
export default TLReceipt;
