import {Card, Layout, Typography} from "antd";
import React, {useEffect, useRef} from "react";
import TLMenu from "../layout/TLMenu";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import {BannerImage} from "../components/BannerImage";
import {useLocation, useNavigate} from "react-router-dom";
import {CheckCircleOutlined} from "@ant-design/icons";

const {Content} = Layout;
const {Title, Paragraph} = Typography;

const EntryReceipt = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const props = location?.state;
  const ref = useRef(null);
  useEffect(() => {
    if (props) {
      setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }else {
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }, [props]);

  return (
    <Layout className="layout">
      <TLMenu/>
      <BannerImage url="/race entry pic.png"/>

      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <TLBreadCrumb/>
        {props && (
          <Card>
            <div ref={ref} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: "50px", gap: 30}}>
              <CheckCircleOutlined style={{color: "green", fontSize: "30px"}}/>
              <Title level={3}>
                {props.event?.toUpperCase()}
              </Title>
              <Title level={4}>
                Your entry has been submitted for {props.horseName}.
              </Title>
              <div className="site-layout-content">

                <Paragraph>
                  {" "}
                  You will receive a receipt for your accepted payment. You can print this page for your records.
                </Paragraph>
                <Paragraph>
                  Please direct any questions to enrollments@tlmamas.com
                </Paragraph>

                <Title level={4}>Entry information: </Title>
                <Title level={5}>Total Amount paid: ${props?.totalPrice}</Title>
                <Paragraph>Horse Name: {props?.horseName}</Paragraph>
                <Paragraph>Registration #: {props?.regNum}</Paragraph>
                <Paragraph>Birth Year: {props?.birthYear}</Paragraph>
                <Paragraph>Rider: {props.rider}</Paragraph>

              </div>
            </div>
          </Card>
        )}
      </Content>
      <TLFooter/>
    </Layout>
  );
};
export default EntryReceipt;
