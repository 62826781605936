import React, {useEffect, useRef, useState} from 'react';
import {Breadcrumb, Button, Card, Col, Row, notification, Tag, Tooltip} from "antd";
import {Form, Select} from "formik-antd";
import {Formik} from "formik";
import {useRealmApp} from "../utils/RealmApp";
import ResultsTable from "../components/ResultsTable";
import {useReactToPrint} from "react-to-print";
import {getEvents} from "../services/events";
import {getPayoutsSummary} from "../services/payouts";
import moment from "moment";
import {SyncOutlined} from "@ant-design/icons";


// years select options
const currentYear = new Date().getFullYear() + 1;
const yearList = [];
for (let year = currentYear; year >= 2021; year--) {
  yearList.push(year.toString());
}

// view select options
const viewOptions = ['Payouts', 'All Results']

const ResultsForm = () => {

  const [eventList, setEventList] = useState([]);
  const [data, setData] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedView, setSelectedView] = useState('');

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();


  const realmApp = useRealmApp();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration: 0,
    });
  };

  const fetchPayoutData = async () => {
    const params = {
      eventName: selectedEvent,
      year: selectedYear,
      sortOption: 'time'
    }
    const data = await getPayoutsSummary(params);
    setData(data);
    handleEventListUpdate(data, 'Payouts');
  }

  const fetchAllResults = async () => {
    const data = await getEvents(realmApp, selectedEvent, selectedYear);
    setData(data.events)
    handleEventListUpdate(data.events, 'All Results');
  }

  useEffect(() => {
    if (selectedEvent && selectedYear) {
      setShow(true)
    }
  }, [selectedEvent, selectedYear]);


  const handleViewChange = (value) => {
    setSelectedYear('');
    setSelectedEvent('');
    setShow(false)
    setSelectedView(value);
  };

  const handleYearChange = (value) => {
    setShow(false)
    setSelectedYear(value);
    setEventList([])
    setSelectedEvent('');

    if (selectedView === 'All Results') {
      handleEventListUpdate(data, 'All Results');
    } else if (selectedView === 'Payouts') {
      handleEventListUpdate(data, 'Payouts');
    }
  };

  const handleEventListUpdate = (data, viewType) => {
    let eventNameList;
    let eventNames;
    if (viewType === 'All Results') {
      eventNameList = data?.filter(sp => sp.eventName).map(sp => sp.eventName);
    } else if (viewType === 'Payouts') {
      eventNameList = data?.filter(p => p.event.name).map(sp => sp.event.name);
    }
    eventNames = [...new Set(eventNameList)];
    if(!selectedEvent) {
      setEventList(eventNames);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (selectedView === 'Payouts') {
          await fetchPayoutData();
        } else if (selectedView === 'All Results') {
          await fetchAllResults();
        }
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Fetch Data",
          `Failed to fetch data ${error.message}`,
          "bottomRight"
        );
      } finally {
        setLoading(false);
      }
    };
    if (selectedView && selectedYear) {
      fetchData();
    }
  }, [selectedView, selectedEvent, selectedYear]);

  const payoutColumns = [
    {
      title: "Placing",
      dataIndex: 'place',
      key: "place",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Rider",
      dataIndex: "rider",
      key: "rider",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (total) => parseFloat(total).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
    },
    {
      title: "Horse Name",
      dataIndex: "horse",
      key: "horse",
    },
    {
      title: "Sire",
      dataIndex: "sireName",
      key: "sireName",
    },
    {
      title: "Sire $$",
      dataIndex: "sireAmount",
      key: "sireAmount",
      render: (total) => parseFloat(total).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
    },
    {
      title: "Nominator",
      dataIndex: "nominatorName",
      key: "nominatorName",
    },
    {
      title: "Nominator $$",
      dataIndex: "nominatorAmount",
      key: "nominatorAmount",
      render: (total) => parseFloat(total).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
    },
    {
      title: "Owner",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Owner, $$",
      dataIndex: "ownerAmount",
      key: "ownerAmount",
      render: (total) => parseFloat(total).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }),
    },
    {
      title: "Side Pot",
      dataIndex: ["sidepot", "class"],
      key: "sidePot",
    },
    {
      title: "Event",
      dataIndex: ["event", "name"],
      key: "eventName",
    },
    {
      title: "Event Date",
      dataIndex: ["event", "date"],
      key: "eventDate",
      render: (date) => {
        if (date && moment(date).isValid()) {
          return moment(date).format('MM-DD-YYYY');
        } else {
          return date ? date.toString() : '';
        }
      }
    },
  ];
  const eventColumns = [
    {
      title: "Placing",
      dataIndex: "place",
      key: "place",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Side Pot Class",
      dataIndex: "class",
      key: "sidepot",
    },
    {
      title: "Horse Name",
      dataIndex: "foalName",
      key: "foalName",
    },
    {
      title: "Rider",
      dataIndex: "riderName",
      key: "rider",
    },
    {
      title: "Sire",
      dataIndex: "sireName",
      key: "sireName",
    },
    {
      title: "Nominator",
      dataIndex: "nominatorName",
      key: "nominatorName",
    },
    {
      title: "Owner",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Event",
      dataIndex: "eventName",
      key: "eventName",
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      render: (date) => {
        if (date && moment(date).isValid()) {
          return moment(date).format('MM-DD-YYYY');
        } else {
          return date ? date.toString() : '';
        }
      }
    },
  ]

  const initialValues = {
    year: selectedYear,
    eventName: selectedEvent,
    view: selectedView
  }
  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}
      <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
        <Col span={24} xs={32} xl={32}>
          <Card title="Select From Options Below To See Results" bordered={false}>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {
              }}
            >
              {(formik) => (
                <Form
                  layout="vertical"
                  labelCol={{span: 6}}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                      <Form.Item
                        label="View"
                        name="view"
                      >
                        <Select
                          name="view"
                          style={{width: "100%"}}
                          onChange={(value) => {
                            formik.setFieldValue('eventName', '');
                            formik.setFieldValue('year', '');
                            handleViewChange(value)
                          }}
                          placeholder={'Select View...'}
                        >
                          {viewOptions.map((view, i) => (
                            <Select.Option
                              key={i + view}
                              value={view}
                            >
                              {view}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                      <div style={{display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Tooltip
                          title="Year Represents Event Season, Not Calendar Year."  color={"gray"}>
                          <Tag color="gray">?</Tag>
                        </Tooltip>
                        <Form.Item
                          label="Year"
                          name="year"
                          style={{ flex: 1}}
                        >
                          <Select
                            name="year"
                            style={{width: "100%"}}
                            onChange={(value) => {
                              handleYearChange(value);
                              formik.setFieldValue('eventName', '');
                            }}
                            placeholder={'Select Year...'}
                            disabled={!selectedView}
                          >
                            {yearList?.map(year =>
                              <Select.Option
                                key={year}
                                value={year}>
                                {year}
                              </Select.Option>)}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5}>

                      <Form.Item
                        label="Event"
                        name="eventName"
                      >
                        <Select
                          name="eventName"
                          style={{width: "100%"}}
                          onChange={(value) => setSelectedEvent(value)}
                          placeholder={'Select Event...'}
                          disabled={!selectedYear || loading}
                          showSearch
                          filterOption={(input, option) => {
                            return (
                              option.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toString().toLowerCase()) >= 0
                            );
                          }}
                        >
                          {eventList?.map((event, i) => (
                            <Select.Option
                              key={i + event}
                              value={event}
                            >
                              {event}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={2} lg={2} xl={2} style={{textAlign: 'end'}}>
                      <Form.Item
                        label=" "
                        name="clear"
                      ><Tooltip title='Clear All Filters'  color={"gray"}>
                        <SyncOutlined style={{fontSize: '24px', fontWeight: 'bold', color: 'gray'}} onClick={() => {
                          formik.resetForm();
                          setSelectedView('')
                          setSelectedYear('')
                          setSelectedEvent('')
                          setShow(false)
                        }}/>
                      </Tooltip>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{textAlign: 'end', margin: '0 auto auto'}}>
                      {show && <Button className="no-print" onClick={handlePrint}>
                        Print Results
                      </Button>}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card>
          <div ref={componentRef} id="print" style={{marginTop: "20px"}}>
            {show && <Card
              title={selectedView === 'Payouts' ? `Tomorrow's Legends' Payouts` : `Tomorrow's Legends Events`}
              bordered={false}>
              <ResultsTable
                columns={selectedView === "Payouts" ? payoutColumns : eventColumns}
                dataSource={data}
                loading={loading}
                selectedView={selectedView}/>
            </Card>}
          </div>
        </Col>
      </Row>

    </>
  );
};

export default ResultsForm;