export const updateSireEnrollment = async (app, id, params) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const sireEnrollments = mongo.db("legends").collection("sireEnrollments");

    return sireEnrollments.findOneAndUpdate({_id: {$oid: id}}, {$set: {...params}})
  } catch (error) {
    console.error("Error updating Sire Enrollment:", error);
  }
};
