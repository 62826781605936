import * as React from "react";
import { useState, useMemo, useEffect } from "react";
import { Checkbox, Input, InputNumber, SubmitButton, Form } from "formik-antd";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  notification,
  Statistic,
  Row,
  Col,
  Divider,
  List,
  Button,
  message,
  Segmented,
} from "antd";
import countryList from "react-select-country-list";
import { useAcceptJs } from "react-acceptjs";
import PaymentDislosure from "../components/PaymentDisclosure";
import axios from "axios";
import { useRealmApp } from "../utils/RealmApp";
import { browserName, isBrowser, isMobile } from "react-device-detect";
import { generateId } from "../utils/utils";

//credit card Data
const values = [500, 750, 1000, 2000];
const valueWithFee = (value, fee = 0.04) => '$' + value * (1 + fee).toFixed(2);
const data = values.map(value => `$${value} = ${valueWithFee(value)}`);

const checkData = ["$250 = $251", "$400 = $401", "$500 = $501"];
const approvedStatusCodes = ["1", "252", "4"];

const authData = {
  apiLoginID: process.env.REACT_APP_AUTHORIZENET_LOGINID,
  clientKey: process.env.REACT_APP_AUTHORIZENET_CLIENTKEY,
};

const { Title, Text, Paragraph } = Typography;

export const Payment = (props) => {
  const options = useMemo(() => countryList().getData(), []);
  const { dispatchData, loading, error } = useAcceptJs({
    environment:
      process.env.REACT_APP_ENV == "production" ? "PRODUCTION" : "SANDBOX",
    authData,
  });
  const [errors, setErrors] = useState({});
  const [expiration, setExpiration] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [isUsingCard, setIsUsingCard] = useState(true);
  const [accountType, setAccountType] = useState("checking");

  let navigate = useNavigate();

  const app = useRealmApp();
  const mongo = app.currentUser.mongoClient("mongodb-atlas");
  const db = mongo.db(process.env.REACT_APP_DB_NAME);

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
    });
  };

  const expirationChange = (e) => {
    let input = e.target.value;
    if (input.length == 2 && expiration.length == 1) {
      setExpiration(`${input}/`);
    } else {
      setExpiration(input);
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {}
    fetchMyAPI();
  }, []);

  function onlyNumber(e) {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (e.target.value === "" || re.test(e.target.value)) {
    }
  }

  function confirmMatching(value1, value2) {
    return value1 === value2;
  }

  function ValidateCreditCardNumber(ccNum) {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if (discovRegEx.test(ccNum)) {
      isValid = true;
    }

    return isValid;
  }

  async function createNominator(values) {
    console.log(values, 'nominator create values')
    return db.collection("nominators").insertOne({
      name: values.nominatorName ? values.nominatorName.toUpperCase() : "",
      address1: values.nominatorAddress1
        ? values.nominatorAddress1.toUpperCase()
        : "",
      address3: values.nominatorAddress3
        ? values.nominatorAddress3.toUpperCase()
        : "",
      email: values.nominatorEmail ? values.nominatorEmail.toUpperCase() : "",
      phone: values.nominatorPhone,
      country: values.nominatorCountry
        ? values.nominatorCountry.toUpperCase()
        : "",
      membershipId: await generateId(),
    });
  }

  function getNominatorByMembershipId(id) {
    return db.collection("nominators").findOne({ membershipId: id });
  }

  function getNominatorMatch(email, phone, name) {
    return db.collection("nominators").findOne({ email, phone, name });
  }

  function createDam(name) {
    name = name ? name.toUpperCase() : "";
    return db
      .collection("dams")
      .insertOne({ name: name ? name.toUpperCase() : "", pending: true });
  }

  function findDam(name) {
    name = name ? name.toUpperCase() : "";
    return db.collection("dams").findOne({ name });
  }

  function createDam(name) {
    name = name ? name.toUpperCase() : "";
    return db
      .collection("dams")
      .insertOne({ name: name ? name.toUpperCase() : "", pending: true });
  }

  function createSire(name) {
    return db
      .collection("sires")
      .insertOne({ name: name ? name.toUpperCase() : "", pending: true });
  }

  function findSire(name) {
    name = name ? name.toUpperCase() : "";
    return db.collection("sires").findOne({ name });
  }

  function createEnrollment(
    props,
    dam,
    sire,
    nominatorId,
    paymentRequest,
    newNominator,
    payment,
  ) {
    return {
      horseName: props.foal.horseName ? props.foal.horseName.toUpperCase() : "",
      email: props.foal.nominatorEmail
        ? props.foal.nominatorEmail.toUpperCase()
        : "",
      sire: sire?.insertedId ? sire.insertedId : sire._id,
      birthYear: Number(props.foal.birthYear),
      registrationNumber: props.foal.registrationNumber,
      sex: props.foal.sex,
      address1: props.foal.address1,
      address2: props.foal.address2,
      city: props.foal.city,
      state: props.foal.state,
      country: props.foal.nominatorCountry,
      phone: props.foal.phone,
      dam: dam?.insertedId ? dam.insertedId : dam._id,
      damSire: { $oid: props.foal.damSireId },
      nominator: nominatorId,
      paymentMethod: isUsingCard ? "credit card" : "check",
      questions: props.foal.questions ? props.foal.questions.toUpperCase() : "",
      price: Number(props.foal.price),
      referral: props.foal.referral,
      papers: props.foal.papers,
      fee: props.foal.fee,
      totalPrice: Number(props.foal.totalPrice),
      amountPaid: Number(paymentRequest.amount),
      newNominator: newNominator,
      originalNominator: nominatorId,
      transactionId: payment.data.transactionId,
      _partition: "tl",
    };
  }

  function createPaymentRequest(props, response, values) {
    const price = isUsingCard
      ? String(props.foal.totalPrice)
      : `${props.foal.price + 1}.00`;

    return {
      amount:
        process.env.REACT_APP_ENV === "production" ? String(price) : "1.00",
      setDataDescriptor: response.opaqueData.dataDescriptor,
      dataValue: response.opaqueData.dataValue,
      customer: {
        firstName: values.name.split(" ").slice(0, -1).join(" "),
        lastName: values.name.split(" ").slice(-1).join(" ") || "",
        foal: props.foal.horseName,
        address: `${values.address1} ${values.address2}`,
        city: values.city || "",
        state: values.state || "",
        zip: values.zip || "",
      },
    };
  }

  return (
    <>
      {contextHolder}
      <Title level={4}>Payment for {props.foal.horseName}</Title>

      <Divider orientation="center">Pricing with Fees</Divider>

      <List
        size="small"
        bordered
        dataSource={isUsingCard ? data : checkData}
        renderItem={(item, index) => <List.Item key={index}>{item}</List.Item>}
      />
      <br></br>
      <Segmented
        options={["Credit Card",
          // "Check"
        ]}
        defaultValue="Credit Card"
        onChange={(selected) => setIsUsingCard(selected === "Credit Card")}
        style={{ marginBottom: "20px" }}
      />
      {isUsingCard && (
        <Formik
          initialValues={{
            name: "",
            cardNumber: "",
            expiration: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            securityCode: "",
            amount: "",
            signed: false,
          }}
          onSubmit={async (values) => {
            try {
              let cardData = {
                cardNumber: String(values.cardNumber),
                month: String(values.expiration.substring(0, 2)),
                year: String(`20${values.expiration.substring(3, 5)}`),
                cardCode: String(values.securityCode),
              };
              let response = await dispatchData({ cardData });

              let paymentRequest = createPaymentRequest(
                props,
                response,
                values,
              );
              let payment = await axios.post(
                `${process.env.REACT_APP_MANAGEMENT_API_URL}/payment`,
                paymentRequest,
              );
              if (approvedStatusCodes.includes(payment.data.messageCode)) {
                props.foal.amountPaid = paymentRequest.amount;
                props.foal.last4CC = cardData.cardNumber.substring(
                  cardData.cardNumber.length - 4,
                );
                props.foal.transactionId = payment.data.transactionId;
                props.foal._partition = "tl";
                props.foal.signed = values.signed;
                props.foal.dateCreated = new Date();

                let nominatorId;
                let newNominator = false;
                if (!props.foal?.membershipId.startsWith("LM")) {
                  // Look for nominator by phone and email
                  const n = await getNominatorMatch(
                    props.foal.nominatorEmail,
                    props.foal.nominatorPhone,
                    props.foal.nominatorName,
                  );
                  if (n) {
                    nominatorId = n._id;
                  } else {
                    // Create new nominator
                    const nominator = await createNominator(props.foal);
                    nominatorId = nominator.insertedId;
                    newNominator = true;
                  }
                } else {
                  // Find nominator by membership id
                  const nominator = await getNominatorByMembershipId(
                    props.foal?.membershipId,
                  );
                  nominatorId = nominator?._id;
                }

                let dam = await findDam(props.foal.dam);
                let sire = await findSire(props.foal.sire);

                if (!dam) dam = await createDam(props.foal.dam);

                if (!sire) sire = await createSire(props.foal.sire);

                const enrollment = createEnrollment(
                  props,
                  dam,
                  sire,
                  nominatorId,
                  paymentRequest,
                  newNominator,
                  payment,
                );

                const e = await db
                  .collection("enrollments")
                  .insertOne(enrollment);
                openNotificationWithIcon(
                  "success",
                  "Payment",
                  "The payment has been accepted and your enrollment form submitted.",
                  "bottomRight",
                );
              } else {
                openNotificationWithIcon(
                  "error",
                  "Payment",
                  payment.data.message || "Error on payment.",
                  "bottomRight",
                );
                throw error;
              }
            } catch (e) {
              let message = "Error on Payment";
              if (e?.messages?.message?.length > 0) {
                message = e.messages.message[0].text;
              }
              if (e?.response?.data?.message) {
                message = e.response.data.message;
              }
              openNotificationWithIcon(
                "error",
                "Payment",
                message,
                "bottomRight",
              );

              try {
                const res = await axios.get("https://geolocation-db.com/json/");
                const errorCreate = new Date();
                let result = await db
                  .collection("errors_enrollments")
                  .insertOne({
                    enrollment: props.foal,
                    error: e,
                    created: errorCreate,
                    isMobile: isMobile,
                    browser: browserName || "Unknown",
                  });
                let message = "Error on Payment";
                if (e?.messages?.message?.length > 0) {
                  message = e.messages.message[0].text;
                }
                if (e?.response?.data?.message) {
                  message = e.response.data.message;
                }

                openNotificationWithIcon(
                  "error",
                  "Payment",
                  message,
                  "bottomRight",
                );
                return;
              } catch (e) {
                openNotificationWithIcon(
                  "error",
                  "Payment",
                  "Page error. Please refresh and retry",
                  "bottomRight",
                );
                return;
              }
            }
            try {
              let email = await axios.post(
                `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/receipt`,
                {
                  nominator: props.foal.nominatorName,
                  registrationNumber: props.foal.registrationNumber,
                  horseName: props.foal.horseName,
                  damSire: props.foal.damSire,
                  birthYear: props.foal.birthYear,
                  price: props.foal.totalPrice,
                  subject: "TLM Enrollment Receipt",
                  email: props.foal.nominatorEmail,
                },
              );
              navigate("/enrolled", { state: props });
            } catch (e) {
              openNotificationWithIcon(
                "error",
                "Payment",
                "Your payment was accepted, but we are having trouble sending the confirmation email. Please reach out to the support team to receive your receipt. ",
                "bottomRight",
              );
            }
          }}
          validate={(values) => {
            const errors = {};
            if (!ValidateCreditCardNumber(values.cardNumber)) {
              errors.cardNumber = "Invalid CC Num";
            }

            if (!values.name) {
              errors.name = "required";
            }

            if (!values.securityCode) {
              errors.securityCode = "required";
            }

            if (!values.expiration) {
              errors.expiration = "required";
            }

            if (!values.address1) {
              errors.address1 = "required";
            }
            if (!values.city) {
              errors.city = "required";
            }
            if (!values.state) {
              errors.state = "required";
            }
            if (!values.zip) {
              errors.zip = "required";
            }
            // else if(!/^[0-9]{5}(?:-[0-9]{4})?$/i.test(values.zip)) {
            //   errors.zip = 'Invalid zip code';
            // }

            if (!values.signed) {
              errors.signed = "You must sign form by checking box.";
            }

            setErrors(errors);
            return errors;
          }}
        >
          {(formik) => (
            <div className="container">
              <Form
                layout="vertical"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                onSubmit={props.handleSubmit}
              >
                <Form.Item
                  label="Name on Card"
                  name="name"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="name" placeholder="Name" />
                </Form.Item>
                <Form.Item
                  label="Card Number"
                  name="cardNumber"
                  type="number"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={onlyNumber}
                >
                  <InputNumber
                    controls={false}
                    style={{ width: "100%" }}
                    name="cardNumber"
                    placeholder="Card Number"
                  />
                </Form.Item>
                <Form.Item
                  label="Expiration"
                  name="expiration"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input
                    maxLength={5}
                    value={expiration}
                    name="expiration"
                    placeholder="Expiration"
                    onChange={expirationChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Security Code"
                  name="securityCode"
                  hasFeedback={true}
                  type="number"
                  showValidateSuccess={true}
                >
                  <Input
                    controls={false}
                    name="securityCode"
                    placeholder="Code"
                  />
                </Form.Item>
                <Form.Item
                  label="Address Line 1"
                  name="address1"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="address1" placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  label="Address Line 2"
                  name="address2"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="address2" placeholder="Address Line 2" />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="city" placeholder="City" />
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="state" placeholder="State" />
                </Form.Item>
                <Form.Item
                  label="Zip"
                  name="zip"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <p>
                    <small>
                      *Foreign Addresses please enter your postal code without
                      spaces. This cannot be left blank.
                    </small>
                  </p>

                  <Input name="zip" placeholder="Zip" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="amount"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Row gutter={[16, 40]}>
                    <Col offset={1}>
                      <Statistic
                        title="Amount Due Today: "
                        value={`$${props.foal.totalPrice}`}
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <PaymentDislosure />
                <Form.Item
                  label="Sign by checking:"
                  name="signed"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Checkbox type="checkbox" name="signed" />
                  {/* {errors.signed &&
              <Text type="danger">{errors.signed}</Text>} */}
                </Form.Item>
                <Form.Item label="" name="submit">
                  <Button.Group size="large">
                    <SubmitButton
                      // style={{
                      //   backgroundColor: "#a36b53",
                      //   borderColor: "#a36b53",
                      // }}
                      type="primary"
                      disabled={false}
                    >
                      Pay Enrollment Fee
                    </SubmitButton>
                  </Button.Group>
                </Form.Item>
              </Form>
            </div>
          )}
        </Formik>
      )}

      {/*       Below here is for check payments
       */}
      {!isUsingCard && (
        <Formik
          initialValues={{
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            accountNumber: "",
            confirmAccountNumber: "",
            routingNumber: "",
            confirmRoutingNumber: "",
            accountType: "",
            amount: "",
            signed: false,
          }}
          onSubmit={async (values) => {
            try {
              let bankData = {
                accountNumber: String(values.accountNumber),
                routingNumber: String(values.routingNumber),
                nameOnAccount: String(values.name),
                accountType: String(accountType),
              };
              let response = await dispatchData({ bankData });

              let paymentRequest = createPaymentRequest(
                props,
                response,
                values,
              );

              let payment = await axios.post(
                `${process.env.REACT_APP_MANAGEMENT_API_URL}/payment`,
                paymentRequest,
              );
              if (approvedStatusCodes.includes(payment.data.messageCode)) {
                props.foal.amountPaid = paymentRequest.amount;
                props.foal.transactionId = payment.data.transactionId;
                props.foal._partition = "lm";
                props.foal.signed = values.signed;
                props.foal.dateCreated = new Date();

                let nominatorId;
                let newNominator = false;
                if (!props.foal?.membershipId.startsWith("LM")) {
                  // Look for nominator by phone and email
                  const n = await getNominatorMatch(
                    props.foal.nominatorEmail,
                    props.foal.nominatorPhone,
                    props.foal.nominatorName,
                  );

                  if (n) {
                    nominatorId = n._id;
                  } else {
                    // Create new nominator
                    const nominator = await createNominator(props.foal);
                    nominatorId = nominator.insertedId;
                    newNominator = true;
                  }
                } else {
                  // Find nominator by membership id
                  const nominator = await getNominatorByMembershipId(
                    props.foal?.membershipId,
                  );
                  nominatorId = nominator?._id;
                }

                let dam = await findDam(props.foal.dam);
                let sire = await findSire(props.foal.sire);

                if (!dam) dam = await createDam(props.foal.dam);

                if (!sire) sire = await createSire(props.foal.sire);

                const enrollment = createEnrollment(
                  props,
                  dam,
                  sire,
                  nominatorId,
                  paymentRequest,
                  newNominator,
                  payment,
                );

                const e = await db
                  .collection("enrollments")
                  .insertOne(enrollment);
                openNotificationWithIcon(
                  "success",
                  "Payment",
                  "The payment has been accepted and your enrollment from submitted.",
                  "bottomRight",
                );
              } else {
                openNotificationWithIcon(
                  "error",
                  "Payment",
                  payment.data.message || "Error on payment.",
                  "bottomRight",
                );
                throw error;
              }
            } catch (e) {
              let message = "Error on Payment";
              if (e?.messages?.message?.length > 0) {
                message = e.messages.message[0].text;
              }
              if (e?.response?.data?.message) {
                message = e.response.data.message;
              }
              openNotificationWithIcon(
                "error",
                "Payment",
                message,
                "bottomRight",
              );

              try {
                const res = await axios.get("https://geolocation-db.com/json/");
                const errorCreate = new Date();
                let result = await db
                  .collection("errors_enrollments")
                  .insertOne({
                    enrollment: props.foal,
                    error: e,
                    created: errorCreate,
                    isMobile: isMobile,
                    browser: browserName || "Unknown",
                  });
                let message = "Error on Payment";
                if (e?.messages?.message?.length > 0) {
                  message = e.messages.message[0].text;
                }
                if (e?.response?.data?.message) {
                  message = e.response.data.message;
                }

                openNotificationWithIcon(
                  "error",
                  "Payment",
                  message,
                  "bottomRight",
                );
                return;
              } catch (e) {
                openNotificationWithIcon(
                  "error",
                  "Payment",
                  "Page error. Please refresh and retry",
                  "bottomRight",
                );
                return;
              }
            }
            try {
              let email = await axios.post(
                `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/receipt`,
                {
                  nominator: props.foal.nominatorName,
                  registrationNumber: props.foal.registrationNumber,
                  horseName: props.foal.horseName,
                  sire: props.foal.sire,
                  damSire: props.foal.damSire,
                  birthYear: props.foal.birthYear,
                  price: props.foal.totalPrice,
                  subject: "LM Enrollment Reciept",
                  email: props.foal.nominatorEmail,
                },
              );
              navigate("/enrolled", { state: props });
            } catch (e) {
              openNotificationWithIcon(
                "error",
                "Payment",
                "Your payment was accepted, but we are having trouble sending the confirmation email. Please reach out to the support team to receive your receipt. ",
                "bottomRight",
              );
            }
          }}
          validate={(values) => {
            // return {};
            const errors = {};

            const accountRegex = /^\d{5,17}$/;
            const routingRegex = /^\d{9}$/;

            if (!values.name) {
              errors.name = "required";
            }

            if (!values.address1) {
              errors.address1 = "required";
            }
            if (!values.city) {
              errors.city = "required";
            }
            if (!values.state) {
              errors.state = "required";
            }
            if (!values.zip) {
              errors.zip = "required";
            }

            if (!values.accountNumber) {
              errors.accountNumber = "required";
            }

            if (!values.confirmAccountNumber) {
              errors.confirmAccountNumber = "required";
            }

            if (values.accountNumber != values.confirmAccountNumber) {
              errors.confirmAccountNumber = "Account numbers do not match";
              errors.accountNumber = "Account numbers do not match";
            }

            if (!accountRegex.test(values.accountNumber)) {
              errors.accountNumber =
                "Account Number should be between 5 and 17 digits.";
            }

            if (!values.routingNumber) {
              errors.routingNumber = "required";
            }

            if (!routingRegex.test(values.routingNumber)) {
              errors.routingNumber = "Routing Number should be 9 digits.";
            }

            if (!values.confirmRoutingNumber) {
              errors.confirmRoutingNumber = "required";
            }

            if (values.routingNumber != values.confirmRoutingNumber) {
              errors.confirmRoutingNumber = "Routing numbers do not match";
              errors.routingNumber = "Routing numbers do not match";
            }

            if (!values.signed) {
              errors.signed = "You must sign form by checking box.";
            }

            setErrors(errors);
            return errors;
          }}
        >
          {(formik) => (
            <div className="container">
              <Form
                layout="vertical"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                onSubmit={props.handleSubmit}
              >
                <Form.Item
                  label="Name on Account"
                  name="name"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="name" placeholder="Name" />
                </Form.Item>
                <Form.Item
                  label="Address Line 1"
                  name="address1"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="address1" placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  label="Address Line 2"
                  name="address2"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="address2" placeholder="Address Line 2" />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="city" placeholder="City" />
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="state" placeholder="State" />
                </Form.Item>
                <Form.Item
                  label="Zip"
                  name="zip"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <p>
                    <small>
                      *Foreign Addresses please enter your postal code without
                      spaces. This cannot be left blank.
                    </small>
                  </p>

                  <Input name="zip" placeholder="Zip" />
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={onlyNumber}
                >
                  <Input
                    controls={false}
                    style={{ width: "100%" }}
                    name="accountNumber"
                    placeholder="Account Number"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Account Number"
                  name="confirmAccountNumber"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={(values) => {
                    confirmMatching(
                      values.accountNumber,
                      values.confirmAccountNumber,
                    );
                  }}
                >
                  <Input
                    controls={false}
                    style={{ width: "100%" }}
                    name="confirmAccountNumber"
                    placeholder="Confirm Account Number"
                  />
                </Form.Item>

                <Form.Item
                  label="Routing Number"
                  name="routingNumber"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={onlyNumber}
                >
                  <Input
                    controls={false}
                    style={{ width: "100%" }}
                    name="routingNumber"
                    placeholder="Routing Number"
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Routing Number"
                  name="confirmRoutingNumber"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  onChange={(values) => {
                    confirmMatching(
                      values.routingNumber,
                      values.confirmRoutingNumber,
                    );
                  }}
                >
                  <Input
                    controls={false}
                    style={{ width: "100%" }}
                    name="confirmRoutingNumber"
                    placeholder="Confirm Routing Number"
                  />
                </Form.Item>

                <Form.Item label="Account Type" name="accountType">
                  <Segmented
                    options={[
                      {
                        label: "Checking",
                        value: "checking",
                      },
                      {
                        label: "Savings",
                        value: "savings",
                      },
                    ]}
                    onChange={(selected) => {
                      setAccountType(selected);
                    }}
                    value={accountType}
                  />
                </Form.Item>

                <Form.Item
                  label=""
                  name="amount"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Row gutter={[16, 40]}>
                    <Col offset={1}>
                      <Statistic
                        title="Amount Due Today: "
                        value={`$${props.foal.price + 1}.00`}
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <PaymentDislosure />
                <Form.Item
                  label="Sign by checking:"
                  name="signed"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Checkbox type="checkbox" name="signed" />
                </Form.Item>
                <Form.Item label="" name="submit">
                  <Button.Group size="large">
                    <SubmitButton
                      // style={{
                      //   backgroundColor: "#a36b53",
                      //   borderColor: "#a36b53",
                      // }}
                      type="primary"
                      disabled={false}
                    >
                      Pay Enrollment Fee
                    </SubmitButton>
                  </Button.Group>
                </Form.Item>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </>
  );
};
