import * as React from "react";
import { useState, useMemo, useEffect, useRef } from "react";
import { Payment } from "./Payment";
import { pricingRulesEngine } from "../utils/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Input, InputNumber, SubmitButton, Select, Form } from "formik-antd";
import { Formik } from "formik";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Typography,
  Switch,
  Row,
  Divider,
  Statistic,
  List,
  Space,
  Col,
  Tag,
  Button,
  message,
  Progress,
  Tooltip,
  Upload,
  Alert,
  TreeSelect as $TreeSelect,
  Checkbox,
  Modal,
} from "antd";
import countryList from "react-select-country-list";
import { StateSelect } from "../components/StateSelect";
import { SireSelect } from "../components/SireSelect";
import { useRealmApp } from "../utils/RealmApp";
import {getSire, getSireByName} from "../services/sires";
import FoalFoundModal from "../components/FoalFoundModal";
import { getOwner } from "../services/owners";
import InfoUpdateRequestModal from "../components/InfoUpdateRequestModal";
import {getAllSortedDamSires} from "../services/damSires";

const { Title, Text, Paragraph } = Typography;

export const FoalEnrollment = (props) => {
  const PENDING = "PENDING";

  const countryLabels = ["USA", "Canada", "Brazil", "European Union", "Other"];

  const [country, setCountry] = useState(
    countryLabels.map((c) => {
      return {
        label: c,
        value: c,
      };
    }),
  );

  const app = useRealmApp();
  const mongo = app.currentUser.mongoClient("mongodb-atlas");
  const db = mongo.db(process.env.REACT_APP_DB_NAME);

  const [damSires, setDamSires] = useState([]);
  const [isCreateNominatorModalOpen, setIsCreateNominatorModalOpen] =
    useState(false);

  const [showPayment, setShowPayment] = useState(false);
  const [showFoalForm, setShowFoalForm] = useState(true);
  const [foalInfo, setFoalInfo] = useState({});
  const [fileUpload, setFileUpload] = useState({});
  const [foalYear, setFoalYear] = useState(0);
  const [sireYear, setSireYear] = useState(0);
  const [damSireName, setDamSireName] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [price, setPrice] = useState(0);
  const [isNomBreeder, setIsNomBreeder] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [errorMessageSubmit, setErrorMessageSubmit] = useState(false);
  const [membershipFound, setMembershipFound] = useState("");
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);


  const nominatorInputRef = React.useRef(null);
  const [nominator, setNominator] = useState(null);
  const [
    isNominatorConfirmationModalOpen,
    setIsNominatorConfirmationModalOpen,
  ] = useState(false);

  const [isNominatorSearchModalOpen, setIsNominatorSearchModalOpen] =
    useState(false);

  const [isNominatorConfirmed, setIsNominatorConfirmed] = useState(false);
  const [regNumExists, setRegNumExists] = useState(false);

  // states for validation if horse exists
  const [validateValues, setValidateValues] = useState({});
  const [foundFoal, setFoundFoal] = useState([]);
  const [foundOwner, setFoundOwner] = useState({});
  const [foundDam, setFoundDam] = useState({});
  const [foundSire, setFoundSire] = useState({});
  const [isFoalModalOpen, setIsFoalModalOpen] = useState(false);
  const [isSibling, setIsSibling] = useState(false);
  const siblingRef = useRef(null);

  const values = [500, 750, 1000, 2000];
  const valueWithFee = (value, fee = 0.04) => '$' + value * (1 + fee).toFixed(2);
  const data = values.map(value => `$${value} = ${valueWithFee(value)}`);

  React.useEffect(() => {
    getAllDamSires();
  }, []);

  const getAllDamSires = async () => {
    let damSires = await getAllSortedDamSires(app);
    setDamSires(damSires);
  };

  const getValidationValues = (name, value) => {
    setValidateValues((prev) => ({ ...prev, [name]: value }));
  };

  function findDam(name) {
    name = name ? name.trim().toUpperCase() : "";
    return db.collection("dams").findOne({ name });
  }

  function findSire(name) {
    name = name ? name.trim().toUpperCase() : "";
    return db.collection("sires").findOne({ name });
  }

  const validateFoal = async () => {
    try {
      const { yob, regNum, sire, damName } = validateValues;
      const dam = await findDam(damName);
      const foundSire = await findSire(sire)

      if (dam) {
        setFoundDam(dam);

        const requestObj = {};
        requestObj.yob = yob.toString();
        requestObj.regNum = regNum?.toLowerCase();
        requestObj.sire = foundSire?._id;
        requestObj.dam = dam?._id;

        if (yob && regNum && foundSire && foundDam) {
          const foundHorses = await axios.post(
            `${process.env.REACT_APP_MANAGEMENT_API_URL}/validate/horse`,
            requestObj,
          );

          if (!!foundHorses?.data.length) {
            const owner = await getOwner(app, foundHorses?.data[0]?.owner);
            setFoundOwner(owner);
            const foundSire = await getSireByName(app, sire);
            setFoundSire(foundSire);
            setFoundFoal(foundHorses.data[0]);
            setIsFoalModalOpen(true);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isSibling) {
      validateFoal();
    }
  }, [validateValues]);

  const closeFoalModal = () => {
    setIsFoalModalOpen(false);
  };

  const handleSiblingConfirm = () => {
    setIsSibling(true);
    setIsFoalModalOpen(false);
    setTimeout(() => {
      siblingRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const errorMessage = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const successMessage = (message) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const handleInputChange = (e) => {
    if (e.target.name == "birthYear" && e.target.value.length == 4) {
      //setFoalYear(Number(e.target.value));
        let amount = pricingRulesEngine(Number(e.target.value));
        setPrice(Number(amount));
    }
  };

  const handleDamSireChange = (e) => {
    const selectedDamSire = damSires.find((ds) => ds._id.toString() === e);
    if (selectedDamSire) {
      setDamSireName(selectedDamSire.name);
      // setSireYear(Number(selectedSire.yearEnrolled));
      // if (foalYear > 0) {
      //   let amount = pricingRulesEngine(
      //     foalYear,
          // Number(selectedDamSire.yearEnrolled),
        // );
        // setPrice(Number(amount));
      // }
    }
  };

  let navigate = useNavigate();

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    setFileUpload({});
    try {
      const { onSuccess, onError, file, onProgress } = options;

      const fmData = new FormData();
      const config = {
        headers: { "Content-Type": file.type },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: { prefix: "papers", name: file.uid, filetype: file.type },
        },
      );

      const res = await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": file.type },
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const editData = () => {
    setShowFoalForm(true);
    setShowPayment(false);
    window.scrollTo(0, 0);
  };

  const handleOnChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const isNomBreederChange = (checked) => {
    setIsNomBreeder(checked);
  };

  function getNominatorByMembershipId(id) {
    return db.collection("nominators").findOne({ membershipId: id });
  }

  function getNominator(id) {
    return db.collection("nominators").findOne({ _id: { $oid: id } });
  }

  function getNominatorByInfo({ name, email, phone }) {
    return db.collection("nominators").findOne({
      $and: [
        { name: { $regex: name.trim(), $options: "i" } },
        {
          $or: [
            { email: { $regex: email.trim(), $options: "i" } },
            { phone: { $regex: phone.replace(/[^\d]/g, "").trim(), $options: "i" } }
          ]
        }
      ]
    });
  }

  function getFoalByRegistrationNumber(regNum) {
    return db.collection("foals").findOne({ regNum });
  }

  function handleRegistrationNumberError() {
    editData();
  }

  const initialValues = {
    horseName: "",
    //set default/initial values via formik
    email: "",
    damSireId: "",
    sire: "",
    birthYear: "",
    sex: "",
    registrationNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    dam: "",
    damSire: "",
    fee: "",
    membershipId: "",
    nominatorName: "",
    nominatorAddress1: "",
    nominatorAddress3: "",
    nominatorCountry: "",
    nominatorEmail: "",
    nominatorPhone: "",
    isNomBreeder: false,
    questions: "",
    price: "",
    referral: [],
    papers: {},
  };

  function handleSubmit(values) {
    values.papers = { ...fileUpload };
    values.price = price;
    values.totalPrice = (price * 1.04).toFixed(2);
    values.fee = price;
    values.isNomBreeder = isNomBreeder;
    values.damSire = damSireName;
    setShowPayment(true);
    setShowFoalForm(false);
    setFoalInfo(values);
    window.scrollTo(0, 0);
  }

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  async function handleValidation(values) {
    const errors = {};

    if (!values.horseName) {
      errors.horseName = "required";
    } else if (!/^[a-zA-Z0-9\s-]+$/i.test(values.horseName)) {
      errors.horseName =
        "Special characters are not allowed. Please enter a valid horse name";
    }

    if (values.registrationNumber) {
      if (values.registrationNumber !== PENDING) {
        const res = await getFoalByRegistrationNumber(
          values.registrationNumber,
        );

        if (res) {
          errors.registrationNumber = "registration number already exists";
        }
      }
    }

    if (!values.registrationNumber) {
      errors.registrationNumber = "required";
    }

    if (!values.birthYear) {
      errors.birthYear = "required";
    } else if (!/^(19|20)\d{2}$/i.test(values.birthYear)) {
      errors.birthYear = "Invalid year";
    }

    if (!values.nominatorEmail) {
      errors.nominatorEmail = "required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.nominatorEmail)
    ) {
      errors.nominatorEmail = "Invalid email address";
    }

    // if (!values.nominatorPhone) {
    //   errors.nominatorPhone = "required";
    // }

    if (!values.sex) {
      errors.sex = "required";
    }

    if (!values.nominatorAddress1) {
      errors.nominatorAddress1 = "required";
    }

    if (!values.nominatorAddress3) {
      errors.nominatorAddress3 = "required";
    }
    if (!values.nominatorName) {
      errors.nominatorName = "required";
    }

    if (!values.damSireId) {
      errors.damSireId = "required";
    }

    if (!values.dam) {
      errors.dam = "required";
    }
    if (!values.sire) {
      errors.sire = "required";
    }
    if (
      !values.membershipId &&
      !values.nominatorAddress1 &&
      !values.nominatorAddress3 &&
      !values.nominatorName &&
      !values.nominatorEmail
    ) {
      errors.membershipId = "provide membership id or create a new nominator";
    }
    // if (!values.country) {
    //   errors.country = "required";
    // }
    // if (!values.address1) {
    //   errors.address1 = "required";
    // }
    // if (!values.city) {
    //   errors.city = "required";
    // }
    // if (!values.state) {
    //   errors.state = "required";
    // }
    // if (!values.zip) {
    //   errors.zip = "required";
    //}
    // else if(!/^[0-9]{5}(?:-[0-9]{4})?$/i.test(values.zip)) {
    //   errors.zip = 'Invalid zip code';
    // }

    if (price == 0) {
      errors.price =
        "An error on pricing has occurred. Please refresh and trying again.";
    }

    if (!Object.keys(fileUpload).length && validateValues.regNum !== PENDING) {
      errors.papers = "required";
    }
    console.log(errors, 'errors')
    return errors;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidation}
    >
      {(formik) => (
        <div className="container">
          {contextHolder}
          {showFoalForm && (
            <Form
              layout="vertical"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
            >
              <Title level={4}>
                Please use this form to enroll all ages of offspring.
              </Title>
              {/*<Space direction="vertical" style={{ width: "100%" }}>*/}
              {/*  <Alert*/}
              {/*    message="International (Non-US) Cardholders"*/}
              {/*    description="We are experiencing a processing issue with International credit cards. If you need to use a credit card from outside the US please email enrollment forms and credit card authorization (available on the “forms” page of our website) to enrollments@tomorrowslegendsllc.com"*/}
              {/*    type="error"*/}
              {/*  />*/}
              {/*</Space>*/}
              <Divider orientation="center"></Divider>

              <Paragraph>
                Please use this form to enroll all ages of offspring
              </Paragraph>

              <Paragraph>
                Nominators may use this form to enroll foals online and pay
                enrollment fees. Nominator must be the owner of horse at time of
                nomination. TLM Pays the Nominator 30% of the payout.{" "}
              </Paragraph>

              <Paragraph>
                Please make sure that you have your paperwork available to
                submit. You will need a copy of your foal's breed registration papers.
              </Paragraph>

              <Paragraph>
               4% fee added for credit card payment. $500 enrollment fee becomes $520 with fee.
              </Paragraph>
              <Divider orientation="center">Pricing with Fees</Divider>
              <List
                size="small"
                bordered
                dataSource={data}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              <br></br>

              <Form.Item
                label="Horse's Registered Name"
                name="horseName"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="horseName" placeholder="Horse Name" />
              </Form.Item>
              <Form.Item
                label="Registration #"
                name="registrationNumber"
                hasFeedback={true}
                showValidateSuccess={true}
                onBlur={() =>
                  getValidationValues(
                    "regNum",
                    formik.values.registrationNumber,
                  )
                }
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Space wrap>
                    <Tooltip
                      title="If horse’s breed registration is pending please click the 'Pending' button."
                      color={"gray"}
                    >
                      <Tag color="gray">?</Tag>
                    </Tooltip>
                  </Space>

                  <Input
                    name="registrationNumber"
                    placeholder="Registration #"
                  />
                  <Button
                    onClick={async () => {
                      await formik.setFieldValue("registrationNumber", PENDING);
                      getValidationValues("regNum", PENDING);
                    }}
                  >
                    Pending
                  </Button>
                </Space.Compact>
              </Form.Item>
              <Form.Item
                label="Birth Year of Foal"
                name="birthYear"
                hasFeedback={true}
                showValidateSuccess={true}
                onChange={(value) => handleInputChange(value)}
                onBlur={(e) => getValidationValues("yob", e.target.value)}
              >
                <InputNumber controls={false} name="birthYear" placeholder="Year"/>
              </Form.Item>
              <Form.Item
                label="Sex"
                name="sex"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Select name="sex" style={{ width: "100%" }} placeholder="Sex">
                  <Select.Option value={"Mare"}>Mare</Select.Option>
                  <Select.Option value={"Gelding"}>Gelding</Select.Option>
                  <Select.Option value={"Stallion"}>Stallion</Select.Option>
                </Select>
              </Form.Item>

              {/* Sire */}
              <div ref={siblingRef}>
                <Form.Item
                  label="Sire Name"
                  name="sire"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input
                    name="sire"
                    label="Sire"
                    placeholder="Sire Name"
                    onBlur={() => getValidationValues("sire", formik.values.sire)}
                  />
                </Form.Item>
                {isSibling && (
                  <Tag
                    color="orange"
                    style={{ margin: "1rem 0", textTransform: "uppercase" }}
                  >
                    Sibling
                  </Tag>
                )}
              </div>
              {/* End Sire */}

              {/* Dam */}
              <Form.Item
                label="Dam Name"
                name="dam"
                hasFeedback={true}
                showValidateSuccess={true}
                onBlur={() => getValidationValues("damName", formik.values.dam)}
              >
                <Input name="dam" label="Dam Name" placeholder="Dam Name" />
              </Form.Item>
              {/* End Dam */}

              {/* Dam Sire */}
              <Form.Item
                label="Dam Sire Name"
                name="damSireId"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Select
                  name="damSireId"
                  placeholder="Dam Sire Name"
                  showSearch
                  onChange={(value) => {
                    handleDamSireChange(value);
                  }}
                  filterOption={(input, option) => {
                    return (
                      option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                    );
                  }}
                >
                  {damSires?.map((ds) => (
                    <Select.Option
                      key={ds._id.toString()}
                      id={ds._id.toString()}
                      value={ds.id}
                    >
                      {ds.name} - {ds.yearEnrolled || ""}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* End Dam Sire */}

              {price > 0 && (
                <Form.Item
                  name="price"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Row gutter={[16, 40]}>
                    <Col offset={6}>
                      <Statistic
                        title="Amount Due based on foal birth year"
                        value={`$${price.toFixed(2)}`}
                      />
                      <Text>
                        with 4% fee totaling ${(price * 1.04).toFixed(2)}{" "}
                      </Text>
                    </Col>
                  </Row>
                </Form.Item>
              )}

              {/* Nominator */}
              <Form.Item
                label="Nominator Membership Id"
                name="membershipId"
                hasFeedback={true}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Space wrap>
                    <Tooltip
                      title="TLM specific nominator ID. If you haven’t nominated horses into TLM before, you do not have an ID. If you are unsure of your ID, please use the Missing member ID."
                      color={"gray"}
                    >
                      <Tag color="gray">?</Tag>
                    </Tooltip>
                  </Space>
                  <Input
                    name="membershipId"
                    ref={nominatorInputRef}
                    disabled={nominator !== null}
                    placeholder="Search Membership ID... "
                  />
                  <Button
                    type="primary"
                    disabled={nominator !== null}
                    onClick={async () => {
                      setNominator(null);
                      const n = await getNominatorByMembershipId(
                        nominatorInputRef.current.input.value,
                      );
                      if (n) {
                        setMembershipFound("");
                        setNominator(n);
                        setIsNominatorConfirmationModalOpen(true);
                      } else {
                        setMembershipFound("Membership ID not found");
                      }
                    }}
                  >
                    Search
                  </Button>
                  {/* Nominator Confirmation */}
                  {isNominatorConfirmationModalOpen && nominator ? (
                    <Modal
                      title="Nominator Info"
                      open={isNominatorConfirmationModalOpen}
                      okText="Confirm and Continue"
                      onCancel={async () => {
                        setIsNominatorConfirmationModalOpen(false);
                        setIsNominatorConfirmed(false);
                        await formik.setValues({
                          ...formik.values,
                          membershipId: "",
                          nominatorName: null,
                          nominatorPhone: null,
                          nominatorEmail: null,
                          nominatorAddress1: null,
                          nominatorAddress3: null,
                          nominatorCountry: null,
                        });
                        setNominator(null);
                      }}
                      onOk={() => {
                        formik.setFieldValue(
                          "membershipId",
                          nominator.membershipId,
                        );
                        formik.setFieldValue("nominatorName", nominator.name);
                        formik.setFieldValue("nominatorPhone", nominator.phone);
                        formik.setFieldValue("nominatorEmail", nominator.email);
                        formik.setFieldValue(
                          "nominatorAddress1",
                          nominator.address1,
                        );
                        formik.setFieldValue(
                          "nominatorAddress3",
                          nominator.address3,
                        );
                        formik.setFieldValue(
                          "nominatorCountry",
                          nominator.country,
                        );
                        setIsNominatorConfirmed(true);
                        setIsNominatorConfirmationModalOpen(false);
                      }}
                    >
                      <div>
                        <b>Name:</b> {nominator.name}
                      </div>
                      <div>
                        <b>Email:</b> {nominator.email}
                      </div>
                      <div>
                        <b>Phone:</b> {nominator.phone}
                      </div>
                      <div>
                        <b>Address:</b> {nominator.address1}{" "}
                        {nominator.address3}
                      </div>
                      <div>
                        <b>Country:</b> {nominator.country}
                      </div>
                      <Divider direction="vertical" />
                      <h4 style={{margin: "2rem 0"}}>
                        A nominator record is found in our system. If any
                        information needs updating, please select the relevant option below.
                      </h4>
                      <Button
                        key="email"
                        // href="https://www.tomorrowslegendsllc.com/infoupdate"
                        // target="_blank"
                        type="primary"
                        onClick={()=> {
                          setIsNominatorConfirmationModalOpen(false);
                          setIsUpdateInfoModalOpen(true);
                        }}
                      >
                        Update Nominator Info
                      </Button>
                    </Modal>
                  ) : // {/* End Nominator Confirmation */}
                  null}
                </Space.Compact>
                {membershipFound && (
                  <Tag style={{ marginTop: "4px" }} color="red">
                    {membershipFound}{" "}
                  </Tag>
                )}
              </Form.Item>
                <InfoUpdateRequestModal
                  entity={"Nominator"}
                  info={nominator}
                  isModalOpen={isUpdateInfoModalOpen}
                  closeModal={()=> {
                    setIsUpdateInfoModalOpen(false)
                  }}
                />

              {/* Nominator Info */}

              {nominator ? (
                <div className="ant-row" style={{ marginBottom: "40px" }}>
                  <div className="ant-col-24">
                    {nominator.membershipId ? (
                      ""
                    ) : (
                      <>
                        <Text type="secondary">
                          Nominator ID not found for given information. A new
                          Nominator ID will be created.
                        </Text>
                        <br />
                        <br />
                      </>
                    )}
                    {nominator.membershipId ? (
                      <>
                        <b>Membership ID:</b> {nominator.membershipId}
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <b>Name:</b> {nominator.name} <br />
                    <b>Address:</b> {nominator.address1} {nominator.address3}{" "}
                    <br />
                    <b>Country:</b> {nominator.country}
                    <br />
                    <b>Phone:</b> {nominator.phone} <br />
                    <b>Email:</b> {nominator.email} <br />
                    <Button
                      onClick={() => {
                        setMembershipFound("");
                        setIsNominatorConfirmed(false);
                        formik.setValues({
                          ...formik.values,
                          membershipId: "",
                          nominatorName: null,
                          nominatorPhone: null,
                          nominatorEmail: null,
                          nominatorAddress1: null,
                          nominatorAddress3: null,
                          nominatorCountry: null,
                        });
                        setNominator(null);
                      }}
                      style={{ marginTop: 10 }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              ) : null}

              {/* End Nominator Info */}

              {/* Create Nominator */}
              <>
                <div className="ant-row" style={{ marginBottom: "40px" }}>
                  <div className="ant-col-4"></div>
                  <div className="ant-col-14">
                    {!nominator && (
                      <Button
                        onClick={() => {
                          setIsCreateNominatorModalOpen(true);
                          formik.setFieldValue("membershipId", "");
                        }}
                        disabled={nominator !== null}
                      >
                        Missing Membership Id?
                      </Button>
                    )}

                    <Modal
                      title="Create Nominator"
                      open={isCreateNominatorModalOpen}
                      onOk={async () => {
                        if (
                          formik.errors.nominatorEmail ||
                          formik.errors.nominatorAddress1 ||
                          formik.errors.nominatorAddress3 ||
                          formik.errors.nominatorCountry ||
                          formik.errors.nominatorPhone ||
                          formik.errors.nominatorName
                        ) {
                          return;
                        }

                        const foundNominator = await getNominatorByInfo({
                          name: formik.values.nominatorName,
                          email: formik.values.nominatorEmail,
                          phone: formik.values.nominatorPhone,
                        });
                        setMembershipFound("");
                        if (foundNominator) {
                          setIsCreateNominatorModalOpen(false)
                          setIsNominatorConfirmationModalOpen(true)
                            setNominator(foundNominator);
                          await formik.setValues({
                            ...formik.values,
                            membershipId: foundNominator.membershipId,
                            nominatorName: foundNominator.name,
                            nominatorPhone: foundNominator.phone,
                            nominatorEmail: foundNominator.email,
                            nominatorAddress1: foundNominator.address1,
                            nominatorAddress3: foundNominator.address3,
                            nominatorCountry: foundNominator.country,
                          });
                        } else {
                          setNominator({
                            name: formik.values.nominatorName,
                            email: formik.values.nominatorEmail,
                            phone: formik.values.nominatorPhone,
                            address1: formik.values.nominatorAddress1,
                            address3: formik.values.nominatorAddress3,
                            country:
                              formik.values.nominatorCountry == "US"
                                ? "USA"
                                : formik.values.nominatorCountry,
                          });
                        }
                        formik.validateForm();
                        setIsCreateNominatorModalOpen(false);
                      }}
                      onCancel={() => setIsCreateNominatorModalOpen(false)}
                    >
                      <Form
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Form.Item
                          layout="vertical"
                          label="Name"
                          name="nominatorName"
                        >
                          <Input name="nominatorName" />
                          <Tag style={{ marginTop: "4px" }} color="red">
                            *Must match Registration papers exactly*
                          </Tag>
                        </Form.Item>

                        <Form.Item label="Address 1" name="nominatorAddress1">
                          <Input name="nominatorAddress1" />
                        </Form.Item>

                        <Form.Item
                          label="City, State, Zip"
                          name="nominatorAddress3"
                        >
                          <Input name="nominatorAddress3" />
                        </Form.Item>
                        <Form.Item
                          label="Country"
                          name="nominatorCountry"
                          hasFeedback={true}
                          showValidateSuccess={true}
                        >
                          <Select
                            addonBefore="Country"
                            options={country}
                            showSearch
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            name="nominatorCountry"
                            placeholder="Country"
                          />
                        </Form.Item>
                        <Form.Item label="Email" name="nominatorEmail">
                          <Input name="nominatorEmail" />
                        </Form.Item>

                        <Form.Item label="Phone" name="nominatorPhone">
                          <Input name="nominatorPhone" />
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>
                </div>
                {/* End Create Nominator */}
                {/* End Nominator */}
              </>
                <FoalFoundModal
                  isFoalModalOpen={isFoalModalOpen}
                  closeFoalModal={closeFoalModal}
                  place={"foalEnrollment"}
                  result={foundFoal}
                  owner={foundOwner}
                  dam={foundDam}
                  sire={foundSire}
                  handleSiblingConfirm={handleSiblingConfirm}
                />

              <Form.Item
                label="Breeder"
                name="isNomBreeder"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <p>Is nominator actual breeder on papers?</p>
                <Switch
                  defaultChecked={isNomBreeder}
                  onChange={isNomBreederChange}
                />
              </Form.Item>
              {/* <Form.Item
                label="Email"
                name="email"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="email" placeholder="Email" />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="phone" placeholder="Phone" />
              </Form.Item> */}
              {/* <Form.Item
                label="Country"
                name="country"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Select
                  addonBefore="Country"
                  options={options}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="country"
                  placeholder="Country"
                />
              </Form.Item>
              <Form.Item
                label="Address Line 1"
                name="address1"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="address1" placeholder="Address Line 1" />
              </Form.Item>
              <Form.Item
                label="Address Line 2"
                name="address2"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="address2" placeholder="Address Line 2" />
              </Form.Item>

              <Form.Item
                label="City"
                name="city"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input name="city" placeholder="City" />
              </Form.Item>
              <Form.Item
                label="State"
                name="state"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                {formik.values.country == "US" ? (
                  <StateSelect />
                ) : (
                  <Input name="state" placeholder="State" />
                )}
              </Form.Item>

              <Form.Item
                label="Zip"
                name="zip"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <p>
                  <small>
                    *Foreign Addresses please enter your postal code without
                    spaces. This cannot be left blank.
                  </small>
                </p>
                <Input name="zip" placeholder="Zip" />
              </Form.Item> */}

              <Form.Item label="Questions?" name="questions">
                <Input.TextArea
                  name="questions"
                  placeholder="Questions or Concerns?"
                />
              </Form.Item>

              <Form.Item label="How did you hear about us?" name="referral">
                <Select
                  name="referral"
                  style={{ width: "100%" }}
                  placeholder="How did you hear about us?"
                  mode="multiple"
                >
                  <Select.Option value={"Barrel Horse News"}>
                    Barrel Horse News
                  </Select.Option>
                  <Select.Option value={"Barrel Racing Report"}>
                    Barrel Racing Report
                  </Select.Option>
                  <Select.Option value={"FACEBOOK"}>Facebook</Select.Option>
                  <Select.Option value={"INSTAGRAM"}>Instagram</Select.Option>
                  <Select.Option value={"FRIEND"}>Friend</Select.Option>
                  <Select.Option value={"WRANGLER HORSE & RODEO NEWS"}>
                    Wrangler Horse & Rodeo News
                  </Select.Option>
                  <Select.Option value={"WEBCAST"}>Webcast</Select.Option>
                  <Select.Option value={"OTHER"}>Other</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Papers" name="papers">
                <Upload
                  accept="*"
                  name="papers"
                  customRequest={uploadImage}
                  onChange={handleOnChange}
                  defaultFileList={defaultFileList}
                  className="image-upload-grid"
                >
                  {defaultFileList.length >= 1 ? null : (
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  )}
                </Upload>
                {validateValues.regNum !== PENDING &&
                  !defaultFileList.length && (
                    <Tag
                      icon={<ExclamationCircleOutlined />}
                      style={{ marginTop: "4px" }}
                      color="warning"
                    >
                      You must upload your papers to enroll
                    </Tag>
                  )}

                {progress > 0 ? <Progress percent={progress} /> : null}
              </Form.Item>
              <Form.Item label="" name="submit">
                <Button.Group size="large">
                  <SubmitButton
                    // style={{
                    //   backgroundColor: "#a36b53",
                    //   borderColor: "#a36b53",
                    // }}
                    disabled={false}
                  >
                    Enroll Foal and Pay
                  </SubmitButton>
                </Button.Group>
              </Form.Item>
              {!formik.isValid && !nominator ? (
                <Tag
                  style={{ whiteSpace: "normal" }}
                  icon={<ExclamationCircleOutlined />}
                  color="error"
                >
                  This form is incomplete. Be sure everything is filled out
                  before continuing.
                </Tag>
              ) : null}
            </Form>
          )}
          <div>
            {showPayment && (
              <div>
                {/* <Button onClick={editData} type="link" block>
                  Edit Foal Data
                </Button> */}
                <Payment foal={foalInfo} />
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};
