import React, {useState} from 'react';
import SireRenewalForm from "./SireRenewalForm";
import SirePaymentForm from "../SirePaymentForm";
import axios from "axios";
import {getSireRenewal} from "../../services/sireRenewals";
import {useRealmApp} from "../../utils/RealmApp";

const SireRenewalIndex = ({renewalValues}) => {
  const app = useRealmApp();


  const [step, setStep] = useState('renewal');
  const [renewalData, setRenewalData] = useState('');

  const onStepChange = (step) => {
    setStep(step)
  }

  const createRenewal = async (values) => {
    try {
      const sireRenewValues = {
        _id: values._id,
        sire: values.sireId,
        contact: values.contact,
        semenAvailability: values.semenAvailability,
        stationName: values.stationName,
        stationAddress: values.stationAddress,
        stationEmail: values.stationEmail,
        stationPhone: values.stationPhone,
        stationWebsite: values.stationWebsite,
        website: values.website,
        socialMedia: values.socialMedia,
        junior: values.junior,
        limited: values.limited,
        country: values.country,
        barrels: values.barrels,
        poles: values.poles,
        lastYearEnrolled: values.lastYearEnrolled,
        notes: values.notes,
        firstCropBirthYear: values.firstCropBirthYear,
        breedingReport: values.breedingReport,
        papers: values.papers,
        headPicture: values.headPicture,
        profilePicture: values.profilePicture,
        video: values.video,
      }

      const result = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/sire-renewal/renewal`,
        sireRenewValues
      )

      const insertedId = result?.data?.renewal?.value?._id || result?.data?.renewal?.insertedId
      if (insertedId) {
        const newRenewal = await getSireRenewal(app, insertedId)
        setRenewalData(newRenewal[0])
        onStepChange('payment')
      }

    } catch (e) {
      throw e;
    }
  }

  return (
    <>
      {step === 'renewal' &&
        <SireRenewalForm renewalValues={renewalValues} onStepChange={onStepChange} onSubmit={createRenewal}/>
      }
      {step === 'payment' &&
        <SirePaymentForm
          onStepChange={()=>setStep('renewal')}
          sireName={renewalData?.sire?.name}
          birthYear={renewalData?.sire?.birthYear}
          regNum={renewalData?.sire?.birthYear}
          data={renewalData}
          applicationType='renewal'
          sireId={renewalData?.sire?._id}
        />
      }
    </>
  );
};

export default SireRenewalIndex;