import React from "react";
import { Layout } from "antd";
import { OwnerChangeForm } from "../modules/OwnerChangeForm";

import TLMenu from "../layout/TLMenu";
import TLFooter from "../layout/TLFooter";
import { BannerImage } from "../components/BannerImage";

const { Content } = Layout;

const OwnerChangePage = (props) => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <TLMenu />
      <BannerImage url="/foal_3.png" />
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">
          <OwnerChangeForm {...props} />
        </div>
      </Content>
      <TLFooter />
    </Layout>
  );
};

export default OwnerChangePage;
