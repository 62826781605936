export const getAllEvents = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const eventsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("events");
    let d = new Date();
    const events = await eventsCollection.aggregate([
      {
        $lookup: {
          from: "entries",
          localField: "_id",
          foreignField: "event",
          as: "entries",
        },
      },
      {
        $match:
          /**
           * query: The query in MQL.
           */
          {
            date: {
              $gte: new Date(d.setDate(d.getDate() - 2)),
            },
          },
      },
      {
        $project: {
          length: {
            $size: "$entries",
          },
          _id: 1,
          name: 1,
          date: 1,
          city: 1,
          state: 1,
          grounds: 1,
          purse: 1,
          round: 1,
          outside_entries: 1,
          type: 1,
        },
      },
      {
        $sort: {
          date: 1,
        },
      },
      {
        $limit: 10,
      },
    ]);
    return {events};
  } catch (error) {
    console.error("Error fetching events:", error);
  }
};

export const getEvents = async (app, eventName, year) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const eventsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("events");
    let lookups = [
      {
        '$lookup': {
          'from': 'sidepots',
          'localField': '_id',
          'foreignField': 'eventId',
          'as': 'sidepot'
        }
      },
      {
        '$unwind': {
          'path': '$sidepot',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$match': {
          'sidepot.finalized': {'$ne': null}
        }
      },
      {
        '$lookup': {
          'from': 'sidePotEntryJoins',
          'localField': 'sidepot._id',
          'foreignField': 'sidePotId',
          'as': 'entry'
        }
      },
      {
        '$unwind': {
          'path': '$entry',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'entries',
          'localField': 'entry.entryId',
          'foreignField': '_id',
          'as': 'entry'
        }
      },
      {
        '$unwind': {
          'path': '$entry',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'placements',
          'let': {'sidePotId': '$sidepot._id', 'entryId': '$entry._id'},
          'pipeline': [
            {
              '$match': {
                '$expr': {
                  '$and': [
                    {'$eq': ['$sidePot', '$$sidePotId']},
                    {'$eq': ['$entryId', '$$entryId']}
                  ]
                }
              }
            }
          ],
          'as': 'sidepot.placement'
        }
      },
      {
        '$unwind': {
          'path': '$sidepot.placement',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'foals',
          'localField': 'entry.foal',
          'foreignField': '_id',
          'as': 'foal'
        }
      },
      {
        '$unwind': {
          'path': '$foal',
          'preserveNullAndEmptyArrays': true
        }
      },
      // If one horse participated in multiple side pots in the SAME event, show only the result for All class or winning side pot per entry
      {
        $group: {
          _id: {
            foal: '$entry.foal',
            event: '$_id'
          },
          sidepots: {$addToSet: '$sidepot'},
          entry: {$addToSet: '$entry'},
          foal: {$addToSet: '$foal'},
          date: {$first: '$date'},
          name: {$first: '$name'},
        }
      },
      {
        $addFields: {
          sidepotsWithPlacements: {
            $filter: {
              input: "$sidepots",
              as: "sidepot",
              cond: {
                $and: [
                  { $ne: ["$$sidepot.placement", null] },
                  { $ne: [{ $type: "$$sidepot.placement" }, "missing"] }
                ]
              }
            }
          }
        }
      },
      {
        $addFields: {
          prioritySidepot: {
            $cond: {
              if: { $gt: [{ $size: "$sidepotsWithPlacements" }, 0] },
              then: {
                $cond: {
                  if: { $in: ["ALL", "$sidepotsWithPlacements.class"] },
                  then: {
                    $arrayElemAt: [
                      { $filter: { input: "$sidepotsWithPlacements", cond: { $eq: ["$$this.class", "ALL"] } } },
                      0
                    ]
                  },
                  else: { $arrayElemAt: ["$sidepotsWithPlacements", 0] }
                }
              },
              else: {
                $arrayElemAt: [
                  { $filter: { input: "$sidepots", cond: { $eq: ["$$this.class", "ALL"] } } },
                  0
                ]
              }
            }
          }
        }
      },
      {
        '$unwind': {
          'path': '$entry',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$unwind': {
          'path': '$foal',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'riders',
          'localField': 'entry.rider',
          'foreignField': '_id',
          'as': 'rider'
        }
      },
      {
        '$unwind': {
          'path': '$rider',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'nominators',
          'localField': 'foal.nominator',
          'foreignField': '_id',
          'as': 'nominator'
        }
      },
      {
        '$unwind': {
          'path': '$nominator',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'owners',
          'localField': 'entry.owner',
          'foreignField': '_id',
          'as': 'owner'
        }
      },
      {
        '$unwind': {
          'path': '$owner',
          'preserveNullAndEmptyArrays': true
        }
      },
      {
        '$lookup': {
          'from': 'sires',
          'localField': 'foal.sire',
          'foreignField': '_id',
          'as': 'sire'
        }
      },
      {
        '$unwind': {
          'path': '$sire',
          'preserveNullAndEmptyArrays': true
        }
      },
    ];

    const yearAdd = [
      {
        '$addFields': {
          'year': {
            '$cond': {
              'if': {
                '$and': [
                  {
                    '$gte': [
                      {'$month': '$date'},
                      11
                    ]
                  },
                  {
                    '$gte': [
                      {'$dayOfMonth': '$date'},
                      15
                    ]
                  }
                ]
              },
              'then': {'$add': [{'$year': '$date'}, 1]},
              'else': {'$year': '$date'}
            }
          }
        }
      }
    ];
    const project = [
      {
        '$project': {
          '_id': 1,
          'entryId': '$entry._id',
          'place': '$prioritySidepot.placement.place',
          'time': '$entry.time',
          'class': '$prioritySidepot.class',
          'riderName': '$rider.name',
          'foalName': '$foal.name',
          'nominatorName': '$nominator.name',
          'ownerName': '$owner.name',
          'sireName': '$sire.name',
          'eventName': '$name',
          'eventDate': '$date',
          'year': 1
        }
      },
    ]
    const matchFilters = [];

    if (eventName) {
      matchFilters.push({
        '$match': {
          'name': eventName
        }
      });
    }

    if (year) {
      matchFilters.push({
        '$match': {
          'year': Number(year)
        }
      });
    }
    const sort = [
      {
        '$addFields': {
          'class_order': {
            '$switch': {
              'branches': [
                { 'case': { '$eq': ['$class', 'ALL'] }, 'then': 0 },
                { 'case': { '$eq': ['$class', 'JUNIOR'] }, 'then': 1 },
                { 'case': { '$eq': ['$class', 'LIMITED'] }, 'then': 2 },
              ],
              'default': 3
            }
          }
        }
      },
      {
        '$sort': {
          'class_order': 1,
          'time': 1
        }
      }
    ];

    const events = await eventsCollection.aggregate([
      ...lookups,
      ...yearAdd,
      ...matchFilters,
      ...project,
      ...sort
    ]);

    return {events};
  } catch (e) {
    throw new Error()
  }
}