import React, {useEffect, useState} from 'react';
import {
  Breadcrumb,
  Button,
  Card,
  notification,
  Segmented,
  Space,
  Tag,
  Typography,
  Upload
} from "antd";
import {Formik} from "formik";
import {Form, Input, Select, SubmitButton} from "formik-antd";
import {FoalSearch} from "../components/FoalSearch";
import {getOwner} from "../services/owners";
import {getDam} from "../services/dams";
import {getAllSortedSires, getSire} from "../services/sires";
import useResponsive from "../hooks/useResponsive";
import {useRealmApp} from "../utils/RealmApp";
import {useLocation} from "react-router-dom";
import OwnerFoalNotFoundModal from "../components/OwnerChangeFoalNotFoundModal";
import OwnerFoalFoundModal from "../components/OwnerChangeFoalFoundModal";
import InfoUpdateRequestModal from "../components/InfoUpdateRequestModal";
import {CheckCircleOutlined, ExclamationCircleOutlined, LeftSquareOutlined, UploadOutlined} from "@ant-design/icons";
import axios from "axios";
import {createHorseChange} from "../services/horseUpdate";
import {getFoalByInfo, getFoal, getFoalAggregate, getFoalById} from "../services/foals";
import {getDamSire} from "../services/damSires";

const {Title} = Typography;

const FoalInfoUpdateForm = () => {

  const app = useRealmApp();
  const [api, contextHolder] = notification.useNotification();
  const [foal, setFoal] = useState({});
  const [pendingHorse, setPendingHorse] = useState({})
  const [dam, setDam] = useState({});
  const [damSire, setDamSire] = useState({});
  const [owner, setOwner] = useState({});
  const [sire, setSire] = useState({});
  const [sires, setSires] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [result, setResult] = useState({});
  const [isResultHorse, setIsResultHorse] = useState(null);
  const [searchFormOption, setSearchFormOption] = useState('Registration Number');
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFoalModalOpen, setIsFoalModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [fileUpload, setFileUpload] = useState({});
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const {isXs, isSm} = useResponsive();
  const isMobile = isXs || isSm;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const regNum = params.get("regnum");
  const foalId = params.get("foalId");

  const openNotificationWithIcon = (type, title, description, placement, duration) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration
    });
  };

  useEffect(() => {
    getAllSires();
  }, []);


  const getAllSires = async () => {
    let sires = await getAllSortedSires(app);
    setSires(sires);
  };

  const getPendingFoalInfo = async () => {
    let result = await getFoalById(app, foalId)
    setFoal(result)
    setResult(result)
    setIsFoalModalOpen(true)
    const fetchedOwner = await getOwner(app, result.owner?.toString());
    setOwner(fetchedOwner);
    const fetchedDam = await getDam(app, result.dam?.toString());
    setDam(fetchedDam);
    const fetchedSire = await getSire(app, result.sire?.toString());
    setSire(fetchedSire);
    const fetchedDamSire = await getDamSire(app, result.damSire?.toString());
    setDamSire(fetchedDamSire);  
  }

  const handleSearchResult = async (data) => {

    setAttempts(1);
    if (regNum !== "PENDING" && regNum?.toString() !== 'null') {
    setResult(data);
    setIsModalOpen(data === "none");
    if (typeof data === "object") {
      setIsResultHorse(true);
      setFoal(data);
      const fetchedOwner = await getOwner(app, data.owner?.toString());
      setOwner(fetchedOwner);
      const fetchedDam = await getDam(app, data.dam?.toString());
      setDam(fetchedDam);
      const fetchedSire = await getSire(app, data.sire?.toString());
      setSire(fetchedSire);
      if (data.damSire) {
        const fetchedDamSire = await getDamSire(app, data.damSire?.toString());
        setDamSire(fetchedDamSire);
      }
    } else {
      setIsResultHorse(false);
    }
    } else {
      await getPendingFoalInfo()

    }   
  };

  // Foal Found Modal
  const openFoalModal = () => {
    setIsFoalModalOpen(true);
  };

  const closeFoalModal = () => {
    setIsFoalModalOpen(false);
    setResult(null);
  };
  // End Foal Found Modal

  // Foal Not Found Modal
  const openModal = () => {
    setIsConfirmed(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // End Foal Not Found Modal


  const handleConfirm = () => {
    setIsConfirmed(true);
    closeFoalModal();
  };

  const handleUpdateFoalInfo = () => {
    setIsConfirmed(true);
    closeFoalModal();
  };

  const redirectToOwnerChange = () => {
    window.location.href = `/owner-change?regnum=${result?.regNum}&foalId=${result?._id}`;
  };

  useEffect(() => {
    if (result && isResultHorse) {
      openFoalModal();
    } else if (result === "none") {
      openModal();
    }
  }, [result, isResultHorse]);

  const sanitizeValues = (string) => string?.toUpperCase().trim()

  const handleSubmit = async (values) => {
    try {
      if (!isConfirmed) {
        const searchParams = values
        const data = await getFoalByInfo(app, searchParams);
        if (data) {
          await handleSearchResult(data);
        } else {
          await handleSearchResult("none");
        }
      } else {
        const originalValues = {
          name: foal?.name || null,
          regNum: foal?.regNum || null,
          damName: dam?.name || null,
          damSireName: damSire?.name || null,
          sex: foal?.sex || null
        }

        const payload = {
          foal: foal?._id,
          name: sanitizeValues(values.name),
          regNum: values.regNum?.trim(),
          dam: sanitizeValues(values.dam),
          damSire: sanitizeValues(values.damSire),
          sex: values.sex,
          papers: fileUpload,
          createdAt: new Date().toISOString(),
          approvedAt: null,
          original: originalValues
        }
        setLoading(true)
        const insertedId = await createHorseChange(app, payload);
        if (insertedId) {
          setIsSubmitted(true)
        }
        setTimeout(() => {
          window.location.href = `/foal-update`;
        }, 6000)
      }
    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Request failed",
        "Please try again later ot reach out to the support team.",
        "bottomRight",
        6000
      );
    } finally {
      setLoading(false)
    }
  }


  const handleFileUpload = ({fileList}) => {
    setDefaultFileList(fileList);
  };

  const uploadFile = async (options) => {
    const {onSuccess, onError, file, onProgress} = options;

    setFileUpload({});
    try {
      const {onSuccess, onError, file, onProgress} = options;

      const fmData = new FormData();
      const config = {
        headers: {"Content-Type": file.type},
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: {prefix: "papers", name: file.uid, filetype: file.type},
        }
      );

      await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: {"Content-Type": file.type},
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      const error = new Error("Some error");
      onError({err});
    }
  };

const SearchForm = ({values, handleChange}) => {
  console.log(values, 'values')
    return (
      <>
        {searchFormOption === "Registration Number" ?
          <>
            <Title level={5}>
              Search if horse is enrolled by breed registration
              number:
            </Title>
            <Space direction="vertical" style={{width: "100%"}}></Space>
            <br></br>
            <Form.Item
              label="Reg #"
              name="registration_number"
              hasFeedback={true}
              showValidateSuccess={true}
            >
              <FoalSearch
                name="registration_number"
                onSearchResult={handleSearchResult}
                prevalue={regNum}
                attempts={attempts}
              />
            </Form.Item>
          </>
          :
          <>
            <Space direction="vertical" style={{width: "100%"}}></Space>
            <br></br>
            <Title level={5}>Search by alternate information :</Title>
            <Tag color="red">* all fields required</Tag>
            <Form.Item
              hasFeedback={true}
              rules={[{required: true}]}
              showValidateSuccess={true}
              label="Year of Birth"
              name="yob"
            >
              <Input name="yob" value={values.yob} onChange={handleChange}/>
            </Form.Item>
            <Form.Item
              label="Sire Name"
              name="sire"
              hasFeedback={true}
              rules={[{required: true}]}
              showValidateSuccess={true}
            >
              <Select
                name="sire"
                placeholder="Sire Name"
                showSearch
                onChange={handleChange}
                filterOption={(input, option) => {
                  return (
                    option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) >= 0
                  );
                }}
              >
                {sires.map((sire) => (
                  <Select.Option
                    key={sire._id.toString()}
                    id={sire._id.toString()}
                    value={sire.id}
                  >
                    {sire.name} - {sire?.yearEnrolled || ""}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              hasFeedback={true}
              rules={[{required: true}]}
              showValidateSuccess={true}
              label="Dam Name"
              name="dam"
            >
              <Input name="dam" value={values.dam} onChange={handleChange}/>
            </Form.Item>
            <Form.Item label="" name="submit">
              <Button.Group size="medium">
                <SubmitButton
                  style={{
                    backgroundColor: "#a36b53",
                    borderColor: "#a36b53",
                    marginTop: isMobile ? "auto" : "1rem",
                    marginLeft: isMobile ? "auto" : "6rem",
                  }}
                  onClick={() => handleSubmit(values)}
                  disabled={false}
                >
                  Search
                </SubmitButton>
              </Button.Group>
            </Form.Item>
          </>}
      </>
    )
  }

  const Success = () => {
    return (
      <Card bordered={false}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <CheckCircleOutlined style={{color: "green", fontSize: "30px", marginBottom: "20px"}}/>
          <Title level={4}>
            Thank you for submitting your request!
          </Title>
          <Title level={5}>
            Your update request has been received. Once approved, the changes will be reflected in the system.
          </Title>
        </div>
      </Card>
    )
  }

  const initialValues = {
    name: foal?.name || '',
    regNum: foal?.regNum || '',
    dam: dam?.name || '',
    damSire: damSire?.name || '',
    yob: foal?.yob || '',
    sex: foal?.sex,
    papers: foal?.papers,
  }

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Name is required';
    }
    if (!values.regNum) {
      errors.regNum = 'Registration Number is required';
    }
    if (!values.dam) {
      errors.dam = 'Dam Name is required';
    }
    if (!values.damSire) {
      errors.damSire = 'Dam Sire Name is required';
    }
    if (!values.sex) {
      errors.sex = 'Sex is required';
    }
    if (!Object.keys(fileUpload).length) {
      errors.papers = "Required";
    }
    return errors;
  }

  return (
    <>
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      ></Breadcrumb>
      {contextHolder}
      <div className="container">
        <Title level={4}>
          {!isSubmitted ? 'Please use this form to update Horse information.' : <br/>}
        </Title>
        <Card>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
            enableReinitialize={true}
          >
            {({values, handleChange}) => (
              <>
                <Form
                  layout="vertical"
                  labelCol={{span: 2}}
                  wrapperCol={{span: 14}}
                >
                  {!isConfirmed && <Segmented
                    defaultValue='Registration Number'
                    style={{
                      marginBottom: 20,
                    }}
                    onChange={(value) => setSearchFormOption(value)}
                    options={['Registration Number', 'Alternate Information']}
                  />}

                  {!isSubmitted ?
                    <>
                      {!isConfirmed &&
                        <>
                          <SearchForm
                            values={values}
                            handleChange={handleChange}
                          />
                        </>}
                      {isConfirmed && (
                        <>
                          <Form.Item
                            label="Name"
                            name="name"
                            hasFeedback={true}
                            showValidateSuccess={true}
                            rules={[{required: true}]}
                          >
                            <Tag style={{marginBottom: "4px"}} color="red">
                              *Must match Registration papers exactly*
                            </Tag>
                            <Input
                              name="name"
                              placeholder="Name"
                              value={values.name}
                              onChange={handleChange}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Reg #"
                            name="regNum"
                            hasFeedback={true}
                            showValidateSuccess={true}
                            rules={[{required: true}]}
                          >
                            <Input
                              name="regNum"
                              placeholder="Registration Number"
                              value={values.regNum}
                              onChange={handleChange}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Dam"
                            name="dam"
                            hasFeedback={true}
                            showValidateSuccess={true}
                            rules={[{required: true}]}
                          >
                            <Input
                              name="dam"
                              placeholder="Dam"
                              value={values.dam}
                              onChange={handleChange}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Dam Sire"
                            name="damSire"
                            hasFeedback={true}
                            showValidateSuccess={true}
                            rules={[{required: true}]}
                          >
                            <Input
                              name="damSire"
                              placeholder="Dam Sire"
                              value={values.damSire}
                              onChange={handleChange}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Sex"
                            name="sex"
                            hasFeedback={true}
                            showValidateSuccess={true}
                            rules={[{required: true}]}
                          >
                            <Select
                              name="sex"
                              style={{width: "100%"}}
                              placeholder="Sex"
                              value={values.sex}
                              onChange={handleChange}
                            >
                              <Select.Option value={"mare"}>Mare</Select.Option>
                              <Select.Option value={"gelding"}>Gelding</Select.Option>
                              <Select.Option value={"stallion"}>Stallion</Select.Option>
                            </Select>
                          </Form.Item>


                          <Form.Item
                            label="Papers"
                            name="papers"
                            rules={[{required: true}]}
                          >
                            <Upload
                              accept="*"
                              name="papers"
                              customRequest={uploadFile}
                              onChange={handleFileUpload}
                              defaultFileList={defaultFileList}
                              className="image-upload-grid"
                            >
                              {defaultFileList.length >= 1 ? null : (
                                <Button icon={<UploadOutlined/>}>Upload</Button>
                              )}
                            </Upload>
                            {!defaultFileList.length &&
                              <Tag
                                style={{whiteSpace: "normal", marginTop: "4px"}}
                                color="warning"
                                icon={<ExclamationCircleOutlined/>}>
                                We must receive papers in your name to approve the horse information update. If papers
                                are
                                at AQHA in transfer, please upload a copy of the signed completed transfer.
                              </Tag>
                            }
                          </Form.Item>
                          <Form.Item label="" name="submit">
                            <Button.Group size="large">
                              <SubmitButton
                                disabled={isSubmitted}
                                loading={loading}
                              >
                                Submit Request
                              </SubmitButton>
                            </Button.Group>
                          </Form.Item>
                          <Form.Item label="" name="back">
                            <Button.Group>
                              <Button
                                href={'foal-update'}>
                                <LeftSquareOutlined/>
                                Back To Search
                              </Button>
                            </Button.Group>
                          </Form.Item>
                        </>
                      )}</> : <Success/>}
                </Form>
              </>
            )}
          </Formik>
        </Card>
      </div>
      <OwnerFoalNotFoundModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
      <OwnerFoalFoundModal
        isFoalModalOpen={isFoalModalOpen}
        closeFoalModal={closeFoalModal}
        result={result}
        owner={owner}
        dam={dam}
        sire={sire}
        handleConfirm={handleConfirm}
        handleOwnerChange={redirectToOwnerChange}
        handleUpdateFoalInfo={handleUpdateFoalInfo}
      />
      <InfoUpdateRequestModal
        entity={"Owner"}
        info={owner}
        isModalOpen={isUpdateInfoModalOpen}
        closeModal={() => setIsUpdateInfoModalOpen(false)}
      />
    </>
  );
};

export default FoalInfoUpdateForm;