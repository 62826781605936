import React, { useState } from "react";
import { Button, Modal, Row, Col, Divider, Descriptions } from "antd";

import "../App.css";
import useResponsive from "../hooks/useResponsive";
import EmailProofOfEnrollmentModal from "./EmailProofOfEnrollmentModal";
import InfoUpdateRequestModal from "./InfoUpdateRequestModal";


const FoalFoundModal = ({
  isFoalModalOpen,
  closeFoalModal,
  openFoalModal,
  owner,
  result,
  dam,
  sire,
  handleOwnerChange,
  handleConfirm,
}) => {
  const [loading, setLoading] = useState(false);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isSendReceiptModalOpen, setIsSendReceiptModalOpen] = useState(false);
  const { isXs, isSm, isMd, isLg, isXl, isXxl } = useResponsive();
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);

  const isMobile = isXs || isSm;

  // Form modal
  const handleShowFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const horseConfirmed = () => {
    setIsFormModalOpen(false);
  };

  //Email Receipt modal
  const closeSendReceiptModal = (type) => {
    setIsSendReceiptModalOpen(false);
  };

  const openSendReceiptModal = (type) => {
    setIsSendReceiptModalOpen(true);
  };

  return (
    <>
      <Modal
        open={isFoalModalOpen}
        onCancel={closeFoalModal}
        cancelText={"Try Again"}
        okText={"Contact Us"}
        onOk={closeFoalModal}
        footer={[
          <Button key="submit" onClick={handleConfirm}>
            Confirm & Close
          </Button>,
        ]}
        width={"45rem"}
        style={{position: isMobile? 'static': 'relative', padding: isMobile? '0px': '' }}
      >
        <div>
          <h4 style={{ margin: "2rem 0" }}>
            Please confirm horse owner information is correct. If any
            information needs updating, please select the relevant option below
          </h4>
        </div>

        <Divider style={{...(isMobile
                  ? { margin: "1rem 0", padding: "0" }
                  : {  }
          )}}/>

        {/* Two columns on the bottom */}
        <Row justify="center">
          <Col
              style={{
                ...(isMobile
                        ? { paddingBottom: "1rem" }
                        : { padding: "1rem" }
                ),
              }}
              xs={24} sm={24} md={12} lg={12}
          >
            {/* Text in the left column */}
            <Descriptions size="small" column={1} title="Horse Information">
              <Descriptions.Item label={<b>Name</b>}>
                {result?.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Registration Number</b>}>
                {result?.regNum}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Sire</b>}>
                {sire?.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Dam</b>}>
                {dam?.name}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Year of Birth</b>}>
                {result?.yob}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Sex</b>}>
                {result?.sex?.toUpperCase()}
              </Descriptions.Item>
              {/* {result.nominatorInfo && 
              <>
              <Descriptions.Item label={<b>Nominator</b>}>
                {result?.nominatorInfo.name?.toUpperCase()}
              </Descriptions.Item>
              <Descriptions.Item label={<b>Membership ID</b>}>
                {result?.nominatorInfo.membershipId?.toUpperCase()}
              </Descriptions.Item> </>} */}
            </Descriptions>
            <Button
              key="email"
              href={`/foal-update?regnum=${result?.regNum}&foalId=${result?._id}`}
              target="_blank"
              type="primary"
              style={{
                marginTop: "15px",
              }}
            >
              Update Horse Info
            </Button>
          </Col>
          <Col
              style={{
                ...(isMobile
                        ? { borderTop: "1px solid #ccc", paddingTop: "1rem" }
                        : { borderLeft: "1px solid #ccc", padding: "1rem" }
                ),
              }}
              xs={24} sm={24} md={12} lg={12}
          >
            {/* Text in the right column */}
            <Descriptions
              size="small"
              column={1}
              title="Owner Information"
              style={{}}
            >
              <Descriptions.Item label={<b>Name</b>}>
                {owner?.name}
              </Descriptions.Item>

              <Descriptions.Item label={<b>Email</b>}>
                {owner?.email}
              </Descriptions.Item>
            </Descriptions>
            <Button
              key="enrollment"
              type="primary"
              href={`/owner-change?regnum=${result?.regNum}&foalId=${result?._id}`}
            >
              Change Horse Ownership
            </Button>
            <Button
              style={{
                marginTop: "15px",
              }}
              // href="https://www.tomorrowslegendsllc.com/infoupdate"
              // target="_blank"
              onClick={() => {
                setIsUpdateInfoModalOpen(true);
                closeFoalModal();
              }}
              type="secondary"
            >
              Update Owner Info
            </Button>
            <Divider style={{...(isMobile
                  ? { margin: "1rem 0", padding: "0" }
                  : {  }
              )}}/>
            <Descriptions
              size="small"
              column={1}
              title="Proof of Enrollment"
              style={{}}
            ></Descriptions>
            <Row justify="start">
            <Button
              key="proofOfEnrollment"
              type="secondary"
              onClick={openSendReceiptModal}
            >
              Email Proof of Enrollment
            </Button>
            </Row>
          </Col>
        </Row>
      </Modal>
      <EmailProofOfEnrollmentModal
        closeSendReceiptModal={closeSendReceiptModal}
        openSendReceiptModal={openSendReceiptModal}
        isSendReceiptModalOpen={isSendReceiptModalOpen}
        foalId={result?._id}
      />
      {isUpdateInfoModalOpen &&
        <InfoUpdateRequestModal
          entity={"Owner"}
          info={owner}
          isModalOpen={isUpdateInfoModalOpen}
          closeModal={()=>setIsUpdateInfoModalOpen(false)}
        />}
    </>
  );
};

export default FoalFoundModal;
