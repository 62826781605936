import React, {useState} from 'react';
import {Form, Input} from "formik-antd";
import {Button, Col, Divider, Progress, Row, Tag, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";

const MarketingInfoForm = ({values, setFieldValue}) => {
  const [fileLists, setFileLists] = useState({
    papers: (values.papers && Object.entries(values.papers).length) ? [values.papers] : [],
    headPicture: (values.headPicture && Object.entries(values.headPicture).length) ? [values.headPicture] : [],
    profilePicture:(values.profilePicture && Object.entries(values.profilePicture).length) ? [values.profilePicture] : [],
    video: (values.video && Object.entries(values.video).length)  ? [values.video] : [],
  });
  const [progress, setProgress] = useState(0);
  const [fileUpload, setFileUpload] = useState({});

  const handleOnChange = (field, {fileList}) => {
    setFileLists((prev) => ({ ...prev, [field]: fileList }));
    setFieldValue(field, fileList[0])
  };

  const uploadImage = async (options, field) => {
    const {onError} = options;

    setFileUpload({});
    try {
      const {onSuccess, file, onProgress} = options;

      const fmData = new FormData();
      const config = {
        headers: {"Content-Type": file.type},

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          setProgress(percent);
          if (percent === 100) {
            setTimeout(() => setProgress(0), 1000);
          }
          onProgress({percent: (event.loaded / event.total) * 100});
        },
      };
      fmData.append("image", file);
      const getUrl = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/customers/upload`,
        {
          input: {prefix: field, name: file.uid, filetype: file.type},
        },
      );

      await fetch(getUrl.data.url, {
        method: "PUT",
        body: file,
        headers: {"Content-Type": file.type},
      });
      setFileUpload(file);
      onSuccess("Ok");
    } catch (err) {
      onError({err});
    }
  };


  return (
    <>

      <Form.Item
        label="Stallion Station Name"
        name="stationName"
        hasFeedback={true}
      >
          <Input
            name="stationName"
            placeholder="Stallion Station Name"
          />
      </Form.Item>
      <Row gutter={[32, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Stallion Station Address"
            name="stationAddress"
            hasFeedback={true}
          >
            <Input
              name="stationAddress"
              placeholder="Stallion Station Address"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Stallion Station Email"
            name="stationEmail"
            hasFeedback={true}
          >
            <Input
              name="stationEmail"
              placeholder="Stallion Station Email"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Stallion Station Phone"
            name="stationPhone"
            hasFeedback={true}
          >
            <Input
              name="stationPhone"
              placeholder="Stallion Station Phone"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Website Link for Stallion Station"
            name="stationWebsite"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="stationWebsite" placeholder="Website Link for Stallion Station"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Website Link for Stallion Page"
            name="website"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="website" placeholder="Website Link for Stallion Page"/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item
            label="Social Media Link"
            name="socialMedia"
            hasFeedback={true}
            showValidateSuccess={true}
          >
            <Input name="socialMedia" placeholder="Social Media Link"/>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="Marketing Paragraph" name="marketingParagraph">
        <Input.TextArea
          name="marketingParagraph"
          placeholder="Marketing Paragraph"
        />
      </Form.Item>

      <Divider orientation="center">Attachments</Divider>

      <Row gutter={[32, 0]}>
        <Col xs={12} sm={6}>
          <Form.Item label="Papers" name="papers">
            <Upload
              accept="*"
              name="papers"
              customRequest={(value)=>uploadImage(value, 'papers')}
              onChange={(value)=>handleOnChange('papers', value)}
              defaultFileList={fileLists.papers}
              className="image-upload-grid"
            >
              {fileLists.papers.length  ? null : (
                <div style={{
                  display: "flex",
                  flexDirection: 'column',
                  gap: 3, justifyContent: 'center',
                  alignItems: 'flex-start'
                }}>
                  <Button icon={<UploadOutlined/>}>Upload</Button>
                  <Tag color="red">
                    *Please upload papers.
                  </Tag>
                </div>
              )}
            </Upload>

            {progress > 0 ? <Progress percent={progress}/> : null}
          </Form.Item>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Item label="Head Picture" name="headPicture">
            <Upload
              accept="*"
              name="pictures"
              customRequest={(value) => uploadImage(value, 'headPicture')}
              onChange={(value) => handleOnChange('headPicture', value)}
              defaultFileList={fileLists.headPicture}
              className="image-upload-grid"
            >
              {fileLists.headPicture.length  ? null : (
                <Button icon={<UploadOutlined/>}>Upload</Button>
              )}
            </Upload>

            {progress > 0 ? <Progress percent={progress}/> : null}
          </Form.Item>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Item label="Profile Picture" name="profilePicture">
            <Upload
              accept="*"
              name="profilePicture"
              customRequest={(value)=>uploadImage(value, 'profilePicture')}
              onChange={(value)=>handleOnChange('profilePicture', value)}
              defaultFileList={fileLists.profilePicture}
              className="image-upload-grid"
            >
              {fileLists.profilePicture.length ? null : (
                <Button icon={<UploadOutlined/>}>Upload</Button>
              )}
            </Upload>

            {progress > 0 ? <Progress percent={progress}/> : null}
          </Form.Item>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Item label="Video" name="video">
            <Upload
              accept="*"
              name="video"
              customRequest={(value)=>uploadImage(value, 'video')}
              onChange={(value)=>handleOnChange('video', value)}
              defaultFileList={fileLists.video}
              className="image-upload-grid"
            >
              {fileLists.video.length ? null : (
                <Button icon={<UploadOutlined/>}>Upload</Button>
              )}
            </Upload>

            {progress > 0 ? <Progress percent={progress}/> : null}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default MarketingInfoForm;