import React, {createRef, Fragment, useEffect, useState} from 'react';
import {Button, Col, Input, Modal, Radio, Row, Space, Tag} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import {Formik} from "formik";
import {Form, Select, SubmitButton} from "formik-antd";
import {getAllSortedSires} from "../services/sires";
import {useRealmApp} from "../utils/RealmApp";


const SireRenewalLookupModal = ({
                                  isModalOpen,
                                  closeModal,
                                  handleSubmit,
                                  step,
                                  setStep,
                                }) => {
  const app = useRealmApp();

  const [sires, setSires] = useState([]);


  const getAllSires = async () => {
    let sires = await getAllSortedSires(app);
    setSires(sires);
  };

  useEffect(() => {
    setStep(1)
    getAllSires()
  }, [isModalOpen]);


  const inputRefs = Array.from({length: 6}, () => createRef());
  const focusNextInput = (index) => {
    if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const validate = (value) => {
    const errors = {}
    if (step === 1 && !value.sireId) {
      errors.sireId = "Sire is required";
    }
    if (step === 2 && (!value[1] && !value[2] && !value[3] && !value[4] && !value[5] && !value[6])) {
      errors[1] = "Invalid code";
    }
    return errors;
  }

  return (
    <>
      <Modal
        centered
        title={step === 1 ? 'Search Stallion' : step === 2 ? 'Enter 6-digit verification code' : 'Success'}
        open={isModalOpen}
        onCancel={closeModal}
        onOk={handleSubmit}
        cancelText={"Cancel"}
        width={"45rem"}
        footer={step === 3 ?
          [<Button key="submit" onClick={closeModal}>
            Close and Continue
          </Button>] :
          null}
      >
        <div style={{marginTop: '20px'}}>
          <Formik
            initialValues={{
              sireId: '',
              contactType: 'email',
              1: '',
              2: '',
              3: '',
              4: '',
              5: '',
              6: ''
            }}
            validate={validate}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({values, handleChange}) => (
              <Form layout="vertical">
                {step === 1 && <div style={{marginTop: '20px'}}>
                  <Form.Item
                    label="Sire Name"
                    name="sireId"
                    hasFeedback={true}
                    rules={[{required: true}]}
                    showValidateSuccess={true}
                  >
                    <Select
                      name="sireId"
                      placeholder="Sire Name"
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toString().toLowerCase()) >= 0
                        );
                      }}
                    >
                      {sires.map((sire) => (
                        <Select.Option
                          key={sire._id.toString()}
                          id={sire._id.toString()}
                          value={sire._id?.toString()}
                        >
                          {sire.name} - {sire?.yearEnrolled || ""}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Preferred method*"
                    name="contactType"
                    hasFeedback={true}
                    rules={[{required: true}]}
                    showValidateSuccess={true}
                  >
                    <Radio.Group
                      name="contactType"
                      value={values.contactType}
                      onChange={handleChange}
                    >
                      <Radio value='email'>Email</Radio>
                      <Radio value='phone'>SMS</Radio>
                    </Radio.Group>
                    <Tag color={'warning'}>*Verification code will be sent to Stallion's owner. </Tag>
                  </Form.Item>
                  <Form.Item label='' name="sendVerificationCode">
                    <SubmitButton>
                      Send Verification Code
                    </SubmitButton>
                  </Form.Item>
                </div>}
                {step === 2 && <div style={{textAlign: 'center'}}>
                  <Space direction="vertical" size="large">
                    <Row gutter={[10, 10]}>
                      {[1, 2, 3, 4, 5, 6].map((item, index) => (
                        <Col xs={24} sm={4} key={index}>
                          <Form.Item
                            label=""
                            name={item}
                            rules={[{required: true}]}
                          >
                            <Input
                              ref={inputRefs[index]}
                              style={{width: '40px', height: '50px', textAlign: 'center'}}
                              type="text"
                              onChange={(e) => {
                                handleChange({target: {name: item.toString(), value: e.target.value}})
                                if (e.target.value) {
                                  focusNextInput(index);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                    <span>Did not receive code? {' '}
                      <a onClick={() => setStep(1)}>
                      Resend code
                      </a>
                    </span>
                  </Space>
                  <Form.Item label='' name="successCode">
                    <SubmitButton
                      style={{marginTop: '15px'}}>
                      Submit Code
                    </SubmitButton>
                  </Form.Item>
                </div>}
                {step === 3 && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutlined style={{color: "green", fontSize: "30px", marginBottom: "10px"}}/>
                  <div><b>Verification Successful!</b></div>
                </div>}
              </Form>
            )}
          </Formik>
        </div>


      </Modal>
    </>
  );
};

export default SireRenewalLookupModal;