import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Divider, Layout, notification, Row} from "antd";
import TLMenu from "../layout/TLMenu";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import {BannerImage} from "../components/BannerImage";
import SireEnrollmentIndex from "../modules/SireEnrollment/SireEnrollmentIndex";
import {UpOutlined} from "@ant-design/icons";
import SireRenewalLookupModal from "../components/SireRenewalLookupModal";
import axios from "axios";
import SireRenewalIndex from "../modules/SireRenewal/SireRenewalIndex";

const {Content} = Layout;

const SireEnrollmentRenewalPage = () => {

  const [option, setOption] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [step, setStep] = useState(1);
  const [sireInfo, setSireInfo] = useState(null);

  const openNotificationWithIcon = (type, title, description, placement, duration) => {
    api[type]({
      message: title,
      description: description,
      placement,
      duration
    });
  };


  const ref = useRef(null);
  useEffect(() => {

    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setShowScrollButton(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (sireInfo?.name) {
      setTimeout(() => {
        ref?.current?.scrollIntoView({behavior: "smooth"});
      }, 100);
    }
  };

  const handleSendVerificationCode = async (values) => {

    try {
      const params = {
        sireId: values.sireId,
        contactType: values.contactType
      }

      await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/sire-renewal/pin`,
        params
      )

      setStep(2)

    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Request failed",
        "Please try again later ot reach out to the support team.",
        "bottomRight",
        6000
      );
    }
  }

  const handleVerifyCode = async (values) => {

    try {
      const pinDigits = ['1', '2', '3', '4', '5', '6'];
      const pinString = pinDigits.map(digit => values[digit]).join('');

      const params = {
        sireId: values.sireId,
        pinString
      }

      const response = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/sire-renewal/get-sire`,
        params
      )
      if (response.status === 200) {
        setSireInfo(response.data.sire[0])
      }
      setStep(3)
      setTimeout(() => {
        closeModal();
        ref?.current?.scrollIntoView({behavior: "smooth"});
      }, 1000)

    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Request failed",
        "Invalid Access Code",
        "bottomRight",
        6000
      );
    }
  }

  const handleSubmit = async (values) => {
    try {

      if (step === 1) {
        await handleSendVerificationCode(values)
      }

      if (step === 2) {
        await handleVerifyCode(values)
      }

    } catch (e) {
      openNotificationWithIcon(
        "error",
        "Request failed",
        "Please try again later ot reach out to the support team.",
        "bottomRight",
        6000
      );
    }
  }


  return (
    <Layout className="layout"
            style={{
              minHeight: "100vh",
            }}
    >
      <TLMenu/>
      <BannerImage url="/Owner Change.png"/>
      <Content
        style={{
          padding: "0 50px"
        }}
      >
        {contextHolder}
        <TLBreadCrumb/>
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
          <Col xs={24} xl={8}>
            <Card title="Sire Enrollment" bordered={false} style={{minHeight: '100%'}}>
              Choose this option to enroll a stallion that was not previously enrolled
              <div>
                <Button
                  onClick={() => {
                    setOption("enroll");
                    setTimeout(() => {
                      ref?.current?.scrollIntoView({behavior: "smooth"});
                    }, 100);
                  }}
                  style={{
                    color: "white",
                    marginTop: "0.5rem",
                  }}
                >
                  Enroll Stallion
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} xl={8}>
            <Card title="Renewal" bordered={false} style={{minHeight: '100%'}}>
              Choose this option if renewing enrollment for a previously enrolled stallion
              <div>
                <Button
                  onClick={() => {
                    setSireInfo(null)
                    setOption("renew");
                    setIsModalOpen(true)
                  }}
                  style={{
                    color: "white",
                    marginTop: "0.5rem",
                  }}
                >
                  Lookup Stallion
                </Button>
              </div>
            </Card>
          </Col>
        </Row>

        {option === "enroll" &&
          <>
            <div ref={ref}>
              <Divider plain/>
            </div>
            <div>
              <SireEnrollmentIndex/>
            </div>
          </>
        }
        {option === "renew" && sireInfo?.name &&
          <>
            <div ref={ref}>
              <Divider plain/>
            </div>
            <SireRenewalIndex
              renewalValues={sireInfo}
            />
          </>
        }
        <SireRenewalLookupModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
          step={step}
          setStep={setStep}
        />
        {showScrollButton && <div style={{
          position: 'fixed',
          bottom: "80px",
          right: "10px",
          transition: 'right 0.5s',
        }}>
          <Button
            type="ghost"
            onClick={scrollToTop}
          >
            <UpOutlined/>
          </Button>
        </div>}
      </Content>
      <TLFooter/>
    </Layout>
  );
};

export default SireEnrollmentRenewalPage;