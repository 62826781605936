import React, { useState } from "react";
import NominatorInformation from "../components/NominatorInformation";
import { getNominatorByPersonalInfo } from "../services/foals";
import { useRealmApp } from "../utils/RealmApp";
import { Input, SubmitButton, Form } from "formik-antd";
import { Formik } from "formik";
import {Typography, Space, Tag, Button, Alert, Segmented} from "antd";
import { MembershipSearch } from "../components/MembershipSearch";
import useResponsive from "../hooks/useResponsive";

const { Title, Text, Paragraph } = Typography;
export const MembershipLookupForm = () => {
  const [nominator, setNominator] = useState({});
  const [searchFormOption, setSearchFormOption] = useState("Membership ID");

  const { isXs, isSm } = useResponsive();

  const isMobile = isXs || isSm;

  const app = useRealmApp();
  const handleSearchResult = async (data) => {
    setNominator(data);
  };

  return (
    <div>
      <Formik
        initialValues={{
          nominatorName: "",
          nominatorEmail: "",
          nominatorAddress: "",
        }}
        onSubmit={async (values, actions) => {
          try {
            const data = await getNominatorByPersonalInfo(app, values);
            if (data) {
              setNominator(data);
            } else {
              setNominator(data);
            }
          } catch (error) {}
        }}
        validate={(values) => {
          const errors = {};
          if (!values.nominatorName) {
            errors.nominatorName = "required";
          }
          if (!values.nominatorEmail) {
            errors.nominatorEmail = "required";
          }
          if (!values.nominatorAddress) {
            errors.nominatorAddress = "required";
          }
          return errors;
        }}
        render={(formik) => (
          <div>
            <Form
              layout="vertical"
              // labelCol={{ span: 2 }}
              // wrapperCol={{ span: 14 }}
            >
              <Segmented
              defaultValue='Membership ID'
              style={{
                marginBottom: 20,
              }}
              onChange={(value) => setSearchFormOption(value)}
              options={['Membership ID', 'Personal Information']}
            />
              {searchFormOption === "Membership ID" &&
              <>
              <Title level={5}>Look up by Membership ID:</Title>
              <Space direction="vertical" style={{ width: "100%" }}></Space>

              <br></br>

              <Form.Item
                label="Membership ID"
                name="membershipId"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <MembershipSearch
                  name="membershipId"
                  onSearchResult={handleSearchResult}
                />
              </Form.Item>
            </>}
              {searchFormOption === "Personal Information" &&
              <>
                <Title level={5}>Look up by personal information:</Title>
              <Tag color="red">* all fields required</Tag>
              <Form.Item
                label="Name"
                hasFeedback={true}
                showValidateSuccess={true}
                name="nominatorName"
              >
                <Tag style={{ marginBottom: "4px" }} color="red">
                  *Must match Registration papers exactly*
                </Tag>
                <Input
                  name="nominatorName"
                  placeholder="Name exactly as on your horse’s breed registration papers"
                />
              </Form.Item>
              <Form.Item
                hasFeedback={true}
                showValidateSuccess={true}
                label="Email"
                name="nominatorEmail"
              >
                <Input name="nominatorEmail" placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Address Line 1"
                hasFeedback={true}
                showValidateSuccess={true}
                name="nominatorAddress"
              >
                <Input name="nominatorAddress" placeholder="Street Address" />
              </Form.Item>
              <Form.Item label="" name="submit">
                <Button.Group size="medium">
                  <SubmitButton
                    style={{
                      // backgroundColor: "#a36b53",
                      // borderColor: "#a36b53",
                      marginTop: isMobile ? "auto" : "1rem",
                      marginLeft:  isMobile ? "auto" : "6rem",
                    }}
                    disabled={false}
                  >
                    Search
                  </SubmitButton>
                </Button.Group>
              </Form.Item>
              </>}
            </Form>
            {nominator?.name && (
              <div>
                <Alert message="Nominator Found!" type="success" />
                <NominatorInformation info={nominator} />
              </div>
            )}
            {!nominator && (
              <div>
                <Alert message={`Nominator not found.`} type="error" />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};
