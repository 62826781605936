import { Typography } from "antd";
const { Paragraph } = Typography;

const EntryPaymentDisclosure = () => {
  return (
    <>
      <Paragraph>
        By signing this form and entering a Legendary Mamas LTD event,  I agree to the
        following terms of Legendary Mamas LTD event entry and participation.
      </Paragraph>
      <Paragraph>
        I agree and hold
        harmless Legendary Mamas LTD, and all Legendary Mamas LTD affiliates, employees,
        officers, agents and contractors (including producers, grounds crew, office staff, facility
        and/or grounds), against all damages and expenses, including reasonable attorneys’ fees
        arising out of injury of this horse and/or rider of this horse incurred by participation in this
        event.
      </Paragraph>
      <Paragraph>
        By participating in this Legendary Mamas LTD event, I agree to allow the likeness
        and   image   of   this   horse,   any   jockey   competing   with   said   horse,   minor,   professional,
        amateur, and/or my likeness and/or image to be used by Legendary Mamas LTD.
      </Paragraph>
    </>
  );
};

export default EntryPaymentDisclosure;