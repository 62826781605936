export const createOwnerChange = async (app, change) => {
    try {
      const mongo = app.currentUser.mongoClient("mongodb-atlas");
      const ownersCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("ownerChanges");
      const owner = await ownersCollection.insertOne(change);
      return owner;
    } catch (error) {
      console.error("Error creating owner change:", error);
    }
  };
  
  export const getOwnerByData = async (app, data) => {
    try {
      const mongo = app.currentUser.mongoClient("mongodb-atlas");
      const ownersCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("ownerChanges");
      const owner = await ownersCollection.findOne(data);
      return owner;
    } catch (error) {
      console.error("Error creating owner change:", error);
    }
  };