import React from "react";
import { Layout } from "antd";
import { RegLookupForm } from "../modules/RegLookupForm";

import TLMenu from "../layout/TLMenu";
import TLFooter from "../layout/TLFooter";
import { BannerImage } from "../components/BannerImage";

const { Content } = Layout;

const RegLookupPage = (props) => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <TLMenu />
      <BannerImage url="/foal_1.png"/>
            <Content
        style={{
          padding: "0 50px",
        }}
      >
        <div className="site-layout-content">
          <RegLookupForm {...props} />
        </div>
      </Content>


      <TLFooter />
    </Layout>
  );
};

export default RegLookupPage;
