import { Layout} from 'antd';
import React from 'react';
const {Footer } = Layout;
const TLFooter = (props)  => {
  const now = new Date()
  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      {`Legendary Mamas LTD ${now.getFullYear()}`}
    </Footer>
)};
export default TLFooter;