import React, {useRef, useState} from "react";
import { Button, Card, Table } from "antd";
import { useReactToPrint } from "react-to-print";
import "./styles.css";
import EmailProofOfEnrollmentModal from "./EmailProofOfEnrollmentModal";
import InfoUpdateRequestModal from "./InfoUpdateRequestModal";
const OwnerInformation = (props) => {
  const [isSendReceiptModalOpen, setIsSendReceiptModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isUpdateInfoModalOpen, setIsUpdateInfoModalOpen] = useState(false);

  const closeSendReceiptModal = () => {
    setIsSendReceiptModalOpen(false);
    setSelectedRowData(null);
  };

  const openSendReceiptModal = (rowData, type) => {
    setIsSendReceiptModalOpen(true);
    setSelectedRowData(rowData)
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Registration Number",
      dataIndex: "regNum",
      key: "regNum",
    },
    {
      title: "Birth Year",
      dataIndex: "yob",
      key: "yob",
    },
    {
      title: "Sex",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "Dam",
      dataIndex: "dam",
      key: "dam",
    },
    {
      title: "Sire",
      dataIndex: "sire",
      key: "sire",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Proof Of Enrollment",
      dataIndex: "proofOfEnrollment",
      key: "proofOfEnrollment",
      render: (_, record) => (
        <Button
          key={record.foalId}
          onClick={()=>openSendReceiptModal(record)}
          type="primary"
        >
          Send Proof Of Enrollment
        </Button>
      ),
    },
    {
      title: "Information",
      dataIndex: "updateHorseInfo",
      key: "updateHorseInfo",
      render: (_, record) => (
        <Button
          key={`${record.foalId}-update`}
          href={`/foal-update?regnum=${record.regNum}`}
          target="_blank"
          type="primary"
        >
          Update Horse Info
        </Button>
      ),
    }
  ];

  const dataSource = props?.info?.horses.map((horse) => {
    return {
      foalId: horse?._id,
      name: horse.name,
      regNum: horse.regNum,
      yob: horse.yob,
      sex: horse.sex,
      dam: horse?.dams[0]?.name || "Unknown",
      sire: horse?.sires[0]?.name || "Unknown",
      owner: horse?.owners[0]?.name || "Unknown",
    };
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
    <div ref={componentRef} id="print">
      {props.info?.horses?.length > 0 ? (
        <div>
          <p>{`${props.info?.horses?.length} horse${
            props.info?.horses?.length > 1 ? "s" : ""
          } found.`}</p>
          <Button className="no-print" onClick={handlePrint}>
            Print Horses
          </Button>
          <Button
            onClick={()=>setIsUpdateInfoModalOpen(true)}
            type="primary"
            style={{
              marginBottom: "15px",
              marginLeft: "5px",
            }}
          >
            Update Owner Info
          </Button>
          <InfoUpdateRequestModal
            entity={"Owner"}
            info={props.info}
            isModalOpen={isUpdateInfoModalOpen}
            closeModal={()=>setIsUpdateInfoModalOpen(false)}
          />
          <Table dataSource={dataSource} pagination={false} columns={columns}  rowKey={(record) => record.foalId}/>
        </div>
      ) : (
        <p>No horses are associated with this owner</p>
      )}
    </div>
      <EmailProofOfEnrollmentModal
        closeSendReceiptModal={closeSendReceiptModal}
        openSendReceiptModal={openSendReceiptModal}
        isSendReceiptModalOpen={isSendReceiptModalOpen}
        foalId={selectedRowData?.foalId}
      />
    </>
  );
};
export default OwnerInformation;
