export const getDam = async (app, damId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const damsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("dams");

    const dam = await damsCollection.findOne({
      _id: { $oid: damId },
    });
    return dam;
  } catch (error) {
    console.error("Error fetching Dam:", error);
  }
};
