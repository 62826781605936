import { Layout } from "antd";
import React from "react";
import { FoalEnrollment } from "../modules/FoalEnrollment";

import TLMenu from "../layout/TLMenu";
import TLBreadCrumb from "../layout/TLBreadCrumb";
import TLFooter from "../layout/TLFooter";
import { BannerImage } from "../components/BannerImage";

const { Content } = Layout;

const EnrollmentPage = (props) => {
  return (
    <Layout className="layout">
      <TLMenu />
      <BannerImage url="purple.png"/>
            <Content
        style={{
          padding: "0 50px",
        }}
      >
        <TLBreadCrumb />
        <div className="site-layout-content">
          <FoalEnrollment {...props} />
        </div>
      </Content>
      <TLFooter />
    </Layout>
  );
};
export default EnrollmentPage;
