export const getAllFoals = async (app) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const foalsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("foals");
    const foals = await foalsCollection.find();
    return { foals };
  } catch (error) {
    console.error("Error fetching foals:", error);
  }
};

export const getFoal = async (app, value) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const foalsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("foals");
    const horse = await foalsCollection.findOne({
      regNum: { $regex: new RegExp(value.trim(), "i") },
    });

    if (!horse) {
      return "none";
    } else {
      return horse;
    }
  } catch (error) {
    console.error("Error fetching foal:", error);
  }
};

export const getFoalById = async (app, foalId) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const foalsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("foals");
    const horse = await foalsCollection.findOne({
      _id: { $oid: foalId },
    });
    return horse;
  } catch (error) {
    console.error("Error fetching foal:", error);
  }
};

export const getNominatorByMembershipId = async (app, value) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const nominatorCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("nominators");
    const nominator = await nominatorCollection.aggregate([
      {
        $match: {
          membershipId: value.trim(),
        },
      },
      {
        $lookup: {
          from: "foals",
          localField: "_id",
          foreignField: "nominator",
          as: "horses",
          pipeline: [
            {
              $lookup: {
                from: "sires",
                localField: "sire",
                foreignField: "_id",
                as: "sires",
              },
            },
            {
              $lookup: {
                from: "dams",
                localField: "dam",
                foreignField: "_id",
                as: "dams",
              },
            },
            {
              $lookup: {
                from: "owners",
                localField: "owner",
                foreignField: "_id",
                as: "owners",
              },
            },
          ],
        },
      },
    ]);

    if (!nominator) {
      return false;
    } else {
      return nominator[0];
    }
  } catch (error) {
    console.error("Error fetching foal:", error);
  }
};

export const getFoalByInfo = async (app, values) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const foalCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("foals");
    const foal = await foalCollection.aggregate([
      // {
      //   $lookup: {
      //     from: "sires",
      //     localField: "sire",
      //     foreignField: "_id",
      //     as: "sires",
      //   },
      // },
      {
        $lookup: {
          from: "dams",
          localField: "dam",
          foreignField: "_id",
          as: "dams",
        },
      },
      {
        $match: {
          // "sires.name": { $regex: values.sire.trim(), $options: "i" },
          "sire": { "$oid": values.sire },
          yob: values.yob?.trim(),
          "dams.name": { $regex: values.dam?.trim(), $options: "i" },
        },
      },
    ]);

    if (!foal) {
      return false;
    } else {
      return foal[0];
    }
  } catch (error) {
    console.error("Error fetching foal:", error);
  }
};
export const getNominatorByPersonalInfo = async (app, values) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const nominatorCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("nominators");
    const nominator = await nominatorCollection.aggregate([
      {
        $match: {
          name: { $regex: values.nominatorName.trim(), $options: "i" },
          email: { $regex: values.nominatorEmail.trim(), $options: "i" },
          address1: { $regex: values.nominatorAddress.trim(), $options: "i" },
        },
      },
      {
        $lookup: {
          from: "foals",
          localField: "_id",
          foreignField: "nominator",
          as: "horses",
          pipeline: [
            {
              $lookup: {
                from: "sires",
                localField: "sire",
                foreignField: "_id",
                as: "sires",
              },
            },
            {
              $lookup: {
                from: "dams",
                localField: "dam",
                foreignField: "_id",
                as: "dams",
              },
            },
            {
              $lookup: {
                from: "owners",
                localField: "owner",
                foreignField: "_id",
                as: "owners",
              },
            },
          ],
        },
      },
    ]);

    if (!nominator) {
      return false;
    } else {
      return nominator[0];
    }
  } catch (error) {
    console.error("Error fetching foal:", error);
  }
};

export const getOwnerByPersonalInfo = async (app, values) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const ownerCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("owners");
    const owner = await ownerCollection.aggregate([
      {
        $match: {
          name: { $regex: values.ownerName?.trim(), $options: "i" },
          email: { $regex: values.ownerEmail?.trim(), $options: "i" },
          address1: { $regex: values.ownerAddress?.trim(), $options: "i" },
        },
      },
      {
        $lookup: {
          from: "foals",
          localField: "_id",
          foreignField: "owner",
          as: "horses",
          pipeline: [
            {
              $lookup: {
                from: "sires",
                localField: "sire",
                foreignField: "_id",
                as: "sires",
              },
            },
            {
              $lookup: {
                from: "dams",
                localField: "dam",
                foreignField: "_id",
                as: "dams",
              },
            },
            {
              $lookup: {
                from: "owners",
                localField: "owner",
                foreignField: "_id",
                as: "owners",
              },
            },
          ],
        },
      },
    ]);

    if (!owner) {
      return false;
    } else {
      return owner[0];
    }
  } catch (error) {
    console.error("Error fetching foal:", error);
  }
};

export const getFoalAggregate = async (app, foalId, aggregates=[]) => {
  try {
    const mongo = app.currentUser.mongoClient("mongodb-atlas");
    const foalsCollection = mongo.db(process.env.REACT_APP_DB_NAME).collection("foals");
    const pipeline = [
      { $match: { _id: foalId } },
      { $unwind: { path: "$_id", preserveNullAndEmptyArrays: true } },
      ...aggregates,
    ];
    return await foalsCollection.aggregate(pipeline);
  } catch (e) {
    console.log("Error fetching foal sire:", e);
    return [];
  }
};