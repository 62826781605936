import React, {useState} from 'react';
import axios from "axios";
import {useAcceptJs} from "react-acceptjs";
import {Button, Card, Col, notification, Row, Statistic} from "antd";
import {Formik} from "formik";
import {Checkbox, Form, Input, InputNumber, SubmitButton} from "formik-antd";
import EntryPaymentDisclosure from "../components/EntryPaymentDisclosure";

const PRICE = 100;
const CC_FEE = 0.04;
const amountDue = PRICE * (1 + CC_FEE);
const approvedStatusCodes = ["1", "252", "4"];

const authData = {
  apiLoginID: process.env.REACT_APP_AUTHORIZENET_LOGINID,
  clientKey: process.env.REACT_APP_AUTHORIZENET_CLIENTKEY,
};

const EntryPaymentForm = ({horseName, completeFormSubmission}) => {

  const [api, contextHolder] = notification.useNotification();
  const [expiration, setExpiration] = useState("");

  const openNotificationWithIcon = (type, title, description, placement) => {
    api[type]({
      message: title,
      description: description,
      placement,
    });
  };

  function ValidateCreditCardNumber(ccNum) {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if (discovRegEx.test(ccNum)) {
      isValid = true;
    }

    return isValid;
  }

  const expirationChange = (e) => {
    let input = e.target.value;
    if (input.length == 2 && expiration.length == 1) {
      setExpiration(`${input}/`);
    } else {
      setExpiration(input);
    }
  };

  const {dispatchData} = useAcceptJs({
    environment:
      process.env.REACT_APP_ENV == "production" ? "PRODUCTION" : "SANDBOX",
    authData,
  });

  const initialValues = {
    nameOnCard: '',
    cardNumber: '',
    expiration: '',
    securityCode: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    amount: '',
    signed: false
  }

  const validate = (values) => {
    const errors = {};
    if (!values.nameOnCard) errors.nameOnCard = 'Name is required';
    if (!values.cardNumber) {
      errors.cardNumber = 'Card is required'
    } else if (!ValidateCreditCardNumber(values.cardNumber)) {
      errors.cardNumber = "Invalid CC Num";
    }
    if (!values.securityCode) {
      errors.securityCode = "required";
    }
    if (!values.expiration) {
      errors.expiration = "required";
    }
    if (!values.address1) {
      errors.address1 = "required";
    }
    if (!values.city) {
      errors.city = "required";
    }
    if (!values.state) {
      errors.state = "required";
    }
    if (!values.zip) {
      errors.zip = "required";
    }
    if (!values.signed) {
      errors.signed = "You must sign form by checking box.";
    }
    return errors;
  }

  const handleSubmit = async (values) => {
    try {
      let cardData = {
        cardNumber: String(values.cardNumber),
        month: String(values.expiration.substring(0, 2)),
        year: String(`20${values.expiration.substring(3, 5)}`),
        cardCode: String(values.securityCode),
      };
      let response = await dispatchData({cardData});
      let paymentRequest = createPaymentRequest(
        response,
        values,
      );

      let payment = await axios.post(
        `${process.env.REACT_APP_MANAGEMENT_API_URL}/payment`,
        paymentRequest,
      );

      if (approvedStatusCodes.includes(payment.data.messageCode)) {
        let cardNumber = String(values.cardNumber).trim()
        const paymentInfo = {
          amountPaid: paymentRequest.amount,
          transactionId: payment.data.transactionId,
          paymentCreated: new Date(),
          last4CC: cardNumber.substring(cardNumber.length - 4),
          paid: true
        }
        await completeFormSubmission(paymentInfo);
      }

      // openNotificationWithIcon(
      //   "success",
      //   "Payment",
      //   "The payment has been accepted and your entry form submitted.",
      //   "bottomRight",
      // );
    } catch (e) {
      let message = "Error on Payment";
      if (e?.messages?.message?.length > 0) {
        message = e.messages.message[0].text;
      }
      if (e?.response?.data?.message) {
        message = e.response.data.message;
      }
      openNotificationWithIcon(
        "error",
        "Payment",
        message,
        "bottomRight",
      );
    }
  }

  function createPaymentRequest(response, values) {

    const payload = {
      amount: process.env.REACT_APP_ENV === "production" ? String(amountDue) : "1.00",
      setDataDescriptor: response.opaqueData.dataDescriptor,
      dataValue: response.opaqueData.dataValue,
      customer: {
        firstName: values.nameOnCard.split(" ").slice(0, -1).join(" "),
        lastName: values.nameOnCard.split(" ").slice(-1).join(" ") || "",
        foal: horseName,
        address: `${values.address1} ${values.address2}`,
        city: values.city || "",
        state: values.state || "",
        zip: values.zip || "",
      },
    };

    return payload
  }


  return (
    <>
      {contextHolder}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {() => (
          <Card title="Please provide payment details."
                bordered={false}>
            <Form layout="vertical">
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Name on Card"
                    name="nameOnCard"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="nameOnCard" placeholder="Name"/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Card Number"
                    name="cardNumber"
                    type="number"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <InputNumber
                      controls={false}
                      style={{width: "100%"}}
                      name="cardNumber"
                      placeholder="Card Number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Expiration"
                    name="expiration"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input
                      maxLength={5}
                      value={expiration}
                      name="expiration"
                      placeholder="Expiration"
                      onChange={expirationChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Security Code"
                    name="securityCode"
                    hasFeedback={true}
                    type="number"
                    showValidateSuccess={true}
                  >
                    <Input
                      controls={false}
                      name="securityCode"
                      placeholder="Code"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Address Line 1"
                    name="address1"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="address1" placeholder="Address Line 1"/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Address Line 2"
                    name="address2"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="address2" placeholder="Address Line 2"/>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[32, 32]}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="City"
                    name="city"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="city" placeholder="City"/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="State"
                    name="state"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="state" placeholder="State"/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Zip"
                    name="zip"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="zip" placeholder="Zip"/>
                    <p>
                      <small>
                        *Foreign Addresses please enter your postal code without
                        spaces. This cannot be left blank.
                      </small>
                    </p>
                  </Form.Item>
                </Col>
              </Row>


              <Form.Item
                label=""
                name="amount"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Row gutter={[32, 32]}>
                  <Col offset={0}>
                    <Statistic
                      title="Amount Due Today: "
                      value={`$${amountDue}`}
                    />
                  </Col>
                </Row>
              </Form.Item>
              <EntryPaymentDisclosure/>
              <Form.Item
                label="Sign by checking:"
                name="signed"
                hasFeedback={true}
              >
                <Checkbox
                  type="checkbox"
                  name="signed"
                />
              </Form.Item>
              <Form.Item label=""
                         name="cancel">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 5,
                    marginTop: '20px'
                  }}
                >
                  <Button
                    size="large"
                    onClick={()=>window.location.reload()}
                  >
                    Cancel
                  </Button>
                  <SubmitButton
                    size="large"
                  >
                    Submit Payment
                  </SubmitButton>
                </div>
              </Form.Item>

            </Form>
          </Card>
        )}
      </Formik>
    </>
  );
};

export default EntryPaymentForm;