import React from 'react';
import {Alert, Divider, Modal, Tag} from "antd";
import {Form, Input} from "formik-antd";

const CreateOwnerModal = ({
                            isCreateOwnerModalOpen,
                            closeCreateOwnerModal,
                            values,
                            errors,
                            setOwner,
                            handleConfirm
                          }) => {
  return (
    <Modal
      title="Create Owner"
      open={isCreateOwnerModalOpen}
      onCancel={closeCreateOwnerModal}
      onOk={() => {
        if (
          errors.ownerName ||
          errors.ownerEmail ||
          errors.ownerAddress1 ||
          errors.ownerAddress3 ||
          errors.ownerPhone
        ) {
          return;
        } else {
          setOwner({
            name: values.ownerName,
            email: values.ownerEmail,
            phone: values.ownerPhone,
            address3: values.ownerAddress1,
            address1: values.ownerAddress3,
          })
          handleConfirm()
          closeCreateOwnerModal();
        }
      }}
    >
      <Alert message={`Owner does not exist in our system. Please fill out the form to create a new owner.`} type="error"/>
      <Divider plain/>
      <Form
        layout="vertical"
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
      >
        <Form.Item
          label="Name"
          name="ownerName"
        >
          <Input
            name="ownerName"
          />
          <Tag style={{marginTop: "4px"}} color="red">
            *Must match Registration papers exactly*
          </Tag>
        </Form.Item>

        <Form.Item label="Address 1" name="ownerAddress1">
          <Input name="ownerAddress1"/>
        </Form.Item>

        <Form.Item
          label="City, State, Zip"
          name="ownerAddress3"
        >
          <Input name="ownerAddress3"/>
        </Form.Item>

        <Form.Item label="Email" name="ownerEmail">
          <Input name="ownerEmail"/>
        </Form.Item>

        <Form.Item label="Phone" name="ownerPhone">
          <Input name="ownerPhone"/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOwnerModal;